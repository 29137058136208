<template>

    <DataTable :value="rows" 
                responsiveLayout="scroll"
               :stripedRows="isStriped"
                v-model:filters="filters" 
                filterDisplay="row"
                dataKey="key"
                ref="dt"
                class="p-datatable-sm"
                removableSort
                sortMode="multiple"
                :multiSortMeta="multiSortMeta">

         <template #header>
                <div style="text-align: left">
                    <Button icon="pi pi-external-link" label="Export" @click="saveFile($event)" />
                </div>
            </template>

        <Column :field="keyLabelColumn" 
                 header=""
                :sortable="true">

            <template #filter="{filterModel,filterCallback}">
                <InputText type="text" 
                           v-model="filterModel.value"
                           @keydown.enter="filterCallback()"/>
            </template>

            <template #footer>
                Summe
            </template>
        </Column>

        <Column field="price" 
                header="Kosten [€]"
                dataType="numeric"
               :sortable="true">

            <template #filter="{filterModel,filterCallback}">
                <InputNumber v-model="filterModel.value" 
                             mode="currency" 
                             currency="EUR" 
                             locale="de-DE"
                             @keydown.enter="filterCallback()"/>
            </template>

            <template #body="sp">
                <div :style="styleNumber(StyleService.formatCurrency(totals.price))">
                    {{StyleService.formatCurrency(sp.data.price)}}
                </div>
            </template>

            <template #footer>
                <div :style="styleNumber(StyleService.formatCurrency(totals.price))">
                    {{StyleService.formatCurrency(totals.price)}}
                </div>
            </template>
        </Column>

        <Column field="mass" 
                filterField="mass"
                header="Menge [kg]"
                dataType="numeric"
               :sortable="true">

            <template #filter="{filterModel,filterCallback}">
                <InputNumber v-model="filterModel.value" 
                             mode="decimal" 
                             :minFractionDigits="2" 
                             :maxFractionDigits="2"
                             suffix=" kg"
                             @keydown.enter="filterCallback()"/>
            </template>

            <template #body="sp">
                <div :style="styleNumber(StyleService.formatDecimal(totals.mass, 'kg'))">
                    {{StyleService.formatDecimal(sp.data.mass, 'kg')}}
                </div>
            </template>

            <template #footer>
                <div :style="styleNumber(StyleService.formatDecimal(totals.mass, 'kg'))">
                    {{StyleService.formatDecimal(totals.mass, 'kg')}}
                </div>
            </template>
        </Column>

        <Column field="frac" 
                header="Menge [%]"
                dataType="numeric"
               :sortable="true">

            <template #filter="{filterModel,filterCallback}">
                <InputNumber v-model="filterModel.value" 
                             mode="decimal" 
                             :minFractionDigits="2" 
                             :maxFractionDigits="2"
                             suffix=" %"
                             @keydown.enter="filterCallback()"/>
            </template>

            <template #body="sp">
                <div :style="styleNumber(StyleService.formatPercentage(totals.frac))">
                    {{StyleService.formatPercentage(sp.data.frac)}}
                </div>
            </template>

            <template #footer>
                <div :style="styleNumber(StyleService.formatPercentage(totals.frac))">
                    {{StyleService.formatPercentage(totals.frac)}}
                </div>
            </template>
        </Column>

        <Column field="fracPrice" 
                header="Kosten [%]"
                dataType="numeric"
               :sortable="true">

            <template #filter="{filterModel,filterCallback}">
                <InputNumber v-model="filterModel.value" 
                             mode="decimal" 
                             :minFractionDigits="2" 
                             :maxFractionDigits="2"
                             suffix=" %"
                             @keydown.enter="filterCallback()"/>
            </template>

            <template #body="sp">
                <div :style="styleNumber(StyleService.formatPercentage(totals.fracPrice))">
                    {{StyleService.formatPercentage(sp.data.fracPrice)}}
                </div>
            </template>

            <template #footer>
                <div :style="styleNumber(StyleService.formatPercentage(totals.fracPrice))">
                    {{StyleService.formatPercentage(totals.fracPrice)}}
                </div>
            </template>
        </Column>


    </DataTable>

    <PlotlyPie :arr="pieRows"
               :values="['mass', 'price']"
               :labels="keyLabelColumn"
                width="100%"/>


</template>

<script>

import StyleService from "../../service/Styleservice.js"
import PlotlyPie from '../charts/plotlyjs/MBPlotlyPie.vue'
import { FilterMatchMode } from 'primevue/api'
import InputNumber from "primevue/inputnumber"

import FileSaver from 'file-saver'

export default {

    components: 
    {
        PlotlyPie,
        InputNumber
    },

    props: 
    {
        totals: Object,
        rows: Array,
        keyLabelColumn: String,
        label: String
    },

    data() 
    {
        var fil = {
                price: {value: null, matchMode: FilterMatchMode.BETWEEN},
                mass: {value: 0.01, matchMode: FilterMatchMode.GREATER_THAN},
                frac: {value: null, matchMode: FilterMatchMode.GREATER_THAN},
                fracPrice: {value: null, matchMode: FilterMatchMode.GREATER_THAN}
        }
        fil[this.keyLabelColumn] = {value: null, matchMode: FilterMatchMode.EQUALS}

        var msm =  [
            {field: 'price', order: -1}
        ]

        return {
            isStriped: false,
            filters: fil,
            multiSortMeta: msm
        }
    },

/*
    mounted()  
    {
        console.log(this.rows)
        this.filteredRows = this.getFilteredRows();
    },
*/
    computed: 
    {
        pieRows()
        {
            if('leftovers' in this.totals)
            {
                return [...this.rows, this.totals.leftovers]
            }
            return this.rows;
        },
    },

    created() 
    {
        this.StyleService = StyleService;
        this.isStriped = this.rows.length > 4;
    },

    methods: 
    {
        styleNumber(reference)
        {
            return {
                        width: reference.length + 'ch', 
                        textAlign: 'right', 
                        //backgroundColor: 'green'
                    }
        },
        
        exportCSV(evt) 
        {
            console.log(evt)
            console.log(this.$refs)

            this.$refs.dt.exportCSV();
        },

        saveFile: function(evt) 
        {
            console.log(this.rows)
            var lines = [];
            var str = ""
            for(var k in this.rows[0])
            {
                str = str + k + ";"
            }
            str = str + "\n";
            lines.push(str)

            for(var ii=0; ii<this.rows.length; ii++)
            {
                str = "";
                for(var k in this.rows[0])
                {
                    var s = this.rows[ii][k]
                    if(isFinite(s))
                    {
                        if(Math.abs(s) > 1e-3)
                        {
                            s = this.rows[ii][k].toLocaleString('de-DE');
                        }
                        else
                        {
                            s = "0";
                        }
                    }                    
                    str = str + s + ";"
                }
                str = str + "\n";
                lines.push(str)
            }
            var blob = new Blob(lines, {type: "text/plain;charset=utf-8"});
            FileSaver.saveAs(blob, this.label + ".csv");
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>