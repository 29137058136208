<template>
    <div>

        <Dialog  v-model:visible="display" 
                :modal="true"
                :style="{width: '75vw'}">

            <template #header>
                <h3> Material {{candidate.materialName}} </h3>
                <p v-if="candidate.targetName">Ziel        :{{candidate.targetName}}</p>
                <p v-else>Ziel        : <em>Alle</em></p>
                <p>Gewicht: {{candidate.value}}</p>
            </template>


                    <div>
                        <h4>Anpassungen für neue Optimierung:</h4>            


                        <p>Einsatz für alle Ziele</p>
                        <div class="grid p-fluid">
                            <div class="col-2">
                                <div class="p-inputgroup">

                                    <span v-if="totalWeightProperties.enabled" class="p-inputgroup-addon">An</span>
                                    <span v-else class="p-inputgroup-addon">Aus</span>

                                    <span class="p-inputgroup-addon">
                                        <Checkbox  v-model="totalWeightProperties.enabled" 
                                                    :binary="true" />
                                    </span>
                                </div>
                            </div>

                            <div :class="getDisabledClass(totalWeightProperties.enabled, 'col-7')">
                                
                                <div class="p-inputgroup">
                                    <span class="p-inputgroup-addon">Min</span>
                                    <InputNumber  v-model="totalWeightProperties.semiLinMinWeight" 
                                                    mode="decimal" 
                                                    locale="de-DE" 
                                                    :minFractionDigits="2"
                                                    suffix=" kg"
                                                    showButtons 
                                                    :min="0" 
                                                    :max="maxWeight"
                                                    :step="weightStepSize"
                                                    @input="onTotalMinInput"/>
                                    <span class="p-inputgroup-addon">Max</span>
                                    <InputNumber  v-model="totalWeightProperties.maxWeight" 
                                                    mode="decimal" 
                                                    locale="de-DE" 
                                                    :minFractionDigits="2"
                                                    suffix=" kg"
                                                    showButtons 
                                                    :min="0" 
                                                    :max="maxWeight"
                                                    :step="weightStepSize"
                                                    @input="onTotalMaxInput"/>
                                </div>
                            </div>
                        </div>

                        <p>Einsatz pro Ziel</p>


                        <DataTable :value="perTargetWeightProperties" 
                                    dataKey="key"
                                    responsiveLayout="scroll">
                            <ColumnGroup type="header">
                                <Row>
                                    <Column header="Ziel"></Column>
                                    <Column header=""></Column>
                                    <Column header="Minimum"></Column>
                                    <Column header="Maximum"></Column>
                                </Row>
                                <Row>
                                    <Column header="Alle"></Column>
                                    <Column>
                                        <template #header>

                                            <div class="p-inputgroup">

                                                <span v-if="perTargetCommonWeightProperties.enabled" class="p-inputgroup-addon">An</span>
                                                <span v-else class="p-inputgroup-addon">Aus</span>

                                                <span class="p-inputgroup-addon">
                                                    <Checkbox  v-model="perTargetCommonWeightProperties.enabled" 
                                                                :binary="true" />
                                                </span>
                                            </div>

                                        </template>
                                    </Column>
                                    
                                    <Column>
                                        <template #header>
                                            <div :class="getDisabledClass(perTargetCommonWeightProperties.enabled, '')">
                                                <InputNumber  v-model="perTargetCommonWeightProperties.semiLinMinWeight" 
                                                            mode="decimal" 
                                                            locale="de-DE" 
                                                            :minFractionDigits="2"
                                                            suffix=" kg"
                                                            showButtons 
                                                            :min="0" 
                                                            :max="maxWeight"
                                                            :step="weightStepSize"
                                                            @input="onPerTargetMinInput"/>
                                            </div>
                                        </template>
                                    </Column>

                                    <Column>
                                        <template #header>
                                            <div :class="getDisabledClass(perTargetCommonWeightProperties.enabled, '')">
                                                <InputNumber  v-model="perTargetCommonWeightProperties.maxWeight" 
                                                                mode="decimal" 
                                                                locale="de-DE" 
                                                                :minFractionDigits="2"
                                                                suffix=" kg"
                                                                showButtons 
                                                                :min="0" 
                                                                :max="maxWeight"
                                                                :step="weightStepSize"
                                                                @input="onPerTargetMaxInput"/>
                                            </div>
                                        </template>
                                    </Column>


                                </Row>
                            </ColumnGroup>
                            <Column field="targetName">
                                    <template #body="slotProps">
                                    {{slotProps.data.targetName}}
                                </template>
                            </Column>
                            <Column field="enabled">
                                <template #body="slotProps">

                                    <div :class="getDisabledClass(!perTargetCommonWeightProperties.enabled, '')">
                                        <div class="p-inputgroup">

                                            <span v-if="!perTargetCommonWeightProperties.enabled & slotProps.data.enabled" 
                                                class="p-inputgroup-addon">An</span>
                                            <span v-else 
                                                class="p-inputgroup-addon">Aus</span>

                                            <span class="p-inputgroup-addon">
                                                <Checkbox  v-model="slotProps.data.enabled" 
                                                        :binary="true" />
                                            </span>
                                        </div>
                                    </div>


                                </template>
                            </Column>
                            
                            <Column field="minWeight">
                                <template #body="slotProps">
                                    <div :class="getDisabledClass(!perTargetCommonWeightProperties.enabled & slotProps.data.enabled, '')">
                                        <InputNumber  v-model="slotProps.data.semiLinMinWeight" 
                                                        mode="decimal" 
                                                        locale="de-DE" 
                                                        :minFractionDigits="2"
                                                        suffix=" kg"
                                                        showButtons 
                                                        :min="0" 
                                                        :max="maxWeight"
                                                        :step="weightStepSize"/>
                                    </div>
                                </template>
                            </Column>

                            <Column field="maxWeight">
                                <template #body="slotProps">
                                    <div :class="getDisabledClass(!perTargetCommonWeightProperties.enabled & slotProps.data.enabled, '')">
                                        <InputNumber  v-model="slotProps.data.maxWeight" 
                                                        mode="decimal" 
                                                        locale="de-DE" 
                                                        :minFractionDigits="2"
                                                        suffix=" kg"
                                                        showButtons 
                                                        :min="0" 
                                                        :max="maxWeight"
                                                        :step="weightStepSize"/>
                                    </div>
                                </template>
                            </Column>
                        </DataTable>

                    </div>

            <template #footer>
                <Button  label="Zurücksetzen" 
                         icon="pi pi-times" 
                        @click="onReset" 
                         class="p-button-text"/>
                <Button  label="Abbrechen"    
                         icon="pi pi-times" 
                        @click="onCloseCancel" 
                         class="p-button-text"/>
                <Button  label="Übernehmen"   
                         icon="pi pi-check" 
                        @click="onCloseOK" 
                         autofocus />
            </template>
        </Dialog>

    </div>
</template>

<script>

import RadioButton from 'primevue/radiobutton';
import InputNumber from 'primevue/inputnumber';
import MultiSelect from 'primevue/multiselect';

export const EVENT_MaterialChangeDisplayClose = 'matdispclose'

export default {
    
    components:
    {
        RadioButton,
        InputNumber,
        MultiSelect
    },

    props: 
    {
        display: Boolean,
        candidate: Object
    },

    emits: [EVENT_MaterialChangeDisplayClose],

    data() {
        return {
            changetype: 'off',
            target: 'all',

            weightStepSize: 50.0,
            maxWeight: null,

            totalWeightProperties: null,
            perTargetCommonWeightProperties: null,
            perTargetWeightProperties: null,
        }
    },

    mounted() 
    {
    },

    watch:
    {
        candidate(newcandidate, oldcandidate)
        {
            if(newcandidate)
            {
                let mat = this.$store.state.currentOrderBlockVariant.getMaterialChanges(newcandidate.material);

                this.maxWeight = mat.properties.mass_max

                this.totalWeightProperties = mat.getOptimizationTotalWeightProperties();
                this.perTargetCommonWeightProperties = mat.getOptimizationPerTargetCommonWeightProperties();
                this.perTargetWeightProperties = [];

                for(let tt of this.$store.state.currentOrderBlockVariant.getTargets())
                {
                    this.perTargetWeightProperties.push(mat.getOptimizationPerTargetWeightProperties(tt.key, tt.properties.type));
                }
            }
        }
    },

    computed: 
    {
        minmaxclass()
        {
            if(this.changetype == "off")
            {
                return "p-disabled minmax"
            }
            else
            {
                return "minmax";
            }
        },
    },

    methods: 
    {
        getMaterialSpec()
        {
            let ret = this.$store.state.currentOrderBlockVariant.getMaterial(this.candidate.material)
            return ret
        },

        onReset()
        {
            console.log("NOT IMPLEMENTED: RESET!");
        },

        onCloseCancel() 
        {
            this.$store.state.currentOrderBlockVariant.resetMaterialChanges(this.candidate.material);

            this.$emit(EVENT_MaterialChangeDisplayClose, false)
        },

        onCloseOK(evt)
        {
            this.$emit(EVENT_MaterialChangeDisplayClose, true)
        },

        getDisabledClass(enabled, originalClass)
        {
            if(enabled)
            {
                return originalClass;
            }
            else
            {
                return originalClass + " p-disabled";                
            }
        },

        onPerTargetMinInput(evt)
        {
            console.log("per target min input: ")
            console.log(evt)
        },

        onPerTargetMaxInput(evt)
        {
            console.log("per target max input: ")
            console.log(evt)
        },

        onPerTargetSelectChange(evt)
        {
            console.log("per target select change: ")
            console.log(evt)
        },

        onTotalMinInput(evt)
        {
            console.log("total min input: ")
            console.log(evt)
            console.log(this.totalWeightProperties)
            this.totalWeightProperties.minWeight = evt.value;
        },

        onTotalMaxInput(evt)
        {
            console.log("total max input: ")
            console.log(evt)
        },

    }
}
</script>

<style scoped>

.minmax {
    padding-left: 20px;
    padding-right: 20px;
}

.tlabel {
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    /*text-align: center;*/
}

.minmaxinput {
    width: 40px;
}

</style>
