import { createRouter, createWebHashHistory } from 'vue-router'

import { isAuth } from '../service/Authentication'

import LoginView from '../views/LoginView.vue'
import HomeView from '../components/simplemix/SimpleMixMain.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
//    component: () => import('../App.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from) => 
{
  console.log("BEFORE EACH!");
  const isAuthenticated = isAuth();
  if (!isAuthenticated && to.name !== 'login') 
  {
    // redirect the user to the login page
    return { name: 'login' }
  }
})

export default router
