<template>

    <div class="panel">

        <TabView v-model:activeIndex="activetab">
            <TabPanel  header="Import"
                      :disabled="importdisabled">
		        <DataImport @dataimport="onDataimport"/>
	        </TabPanel>
	        <TabPanel  header="Material"
                      :disabled="materialsdisabled">
		        <Materials/>
	        </TabPanel>
	        <TabPanel  header="Ziele"
                      :disabled="targetsdisabled">
		        <Targets/>
	        </TabPanel>
	        <TabPanel  header="Mix"
                      :disabled="mixdisabled">
		        <Mixer @viewfocus="onViewFocus"/>
                <Results/>
	        </TabPanel>
        </TabView>

    </div>

</template>

<script>

import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import DataImport from '../optimization/MBDataImport.vue'
import Targets from '../optimization/MBTargets.vue'
import Materials from '../optimization/MBMaterials.vue'

import Mixer from './SimpleMixMixer.vue'

import Results from '../optimization/MBResults.vue'

export default {

  components: 
  {
      TabView,
      TabPanel,
      DataImport,
      Targets,
      Materials,
      Mixer,
      Results
  },

  data()
  {
      return {
          importdisabled: false,
          materialsdisabled: true,
          targetsdisabled: true,
          mixdisabled: true,
          activetab: 0
      }
  },

  methods: {
        
        onDataimport(evt)
        {
            if(evt.materials > 0)
            {
                this.materialsdisabled = false;
            }
            if(evt.targets > 0)
            {
                this.targetsdisabled = false;
            }

            if(!this.materialsdisabled && !this.targetsdisabled)
            {
                this.mixdisabled = false;
                this.activetab = 3;
                this.$toast.add({severity: 'info', 
                                summary: evt.targets + " Ziele  & " + evt.materials + " Materialien importiert", 
                                life: 2000});
            }
        },

        onViewFocus(evt)
        {
//            this.$emit("viewfocus", {type: evt.type, key: evt.key})
        },

  }

}

</script>

<style scoped>

div.panel {
    display: block;
    padding: 2pt 2pt 2pt 2pt;
    background-color: var(--surface-100);
}

div.hide {
    display: none;
}


</style>
