<template>

<!--
    <Panel :toggleable="true">
        <template #header>
            <h3> Ergebnisse </h3>
        </template>

        <OptimizationResults/>

    </Panel>
-->
    <OptimizationResults/>


</template>

<script>

import OptimizationResults from './MBOptimizationResults.vue'

export default {

    components: 
    {
        OptimizationResults,
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>