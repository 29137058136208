import SpecService from "../service/SpecService";

export default class OrderBlock
{
    constructor(name)
    {
        this.key = undefined;
        this.materialsMap = new Map();
        this.targetsMap = new Map();

        this.solution = [];
        this.solutionByTarget = new Map();
        this.solutionByMaterial = new Map();
        this.summary = {};
        this.summary.totalCost = 0;
        this.summary.totalMass = 0;

        this.name = name;

        this.derivedVariants = [];

        this.semiMinMaterialWeightDefault = 0.0;
        this.semiMinMaterialPerTargetWeightDefault = 0.0;

        this.optError = undefined;

        this.justAdded = true;

        this.materialsMapChanges = undefined;
    }

    getNumMaterialsTotal()
    {
        return this.materialsMap.size;
    }

    getNumMaterialsSelected()
    {
        var cnt=0;
        for(var mm of this.materialsMap.values())
        {
            if(mm.useInOptimization)
            {
                cnt++;
            }
        }
        return cnt;
    }

    getNumTargetsTotal()
    {
        return this.targetsMap.size;
    }

    getNumTargetsSelected()
    {
        var cnt=0;
        for(var tt of this.targetsMap.values())
        {
//            if(tt.useInOptimization)
            if(tt.getUseInOptimization())
            {
                cnt++;
            }
        }
        return cnt;
    }

    isReadyForOpt()
    {
        return this.getNumMaterialsSelected() > 0 && this.getNumTargetsSelected() > 0;
    }

    hasFailed()
    {
        return this.optError !== undefined;
    }

    hasResults()
    {
        return this.solution.length > 0;
    }

    isReadOnly()
    {
        return this.hasResults() || this.hasFailed();
    }

    setOptError(optError, errorData=undefined)
    {
        this.clearResults();
        this.optError = optError;
        this.optErrorData = errorData;
    }

    getOptErrorMessage()
    {
        return this.optError;
    }

    clearResults()
    {
        this.solution = [];
        this.solutionByTarget = new Map();
        this.solutionByMaterial = new Map();
        this.summary = {};
        this.summary.totalCost = 0;
        this.summary.totalMass = 0;
        this.optError = undefined;
    }

/*    
    transferMaterialsConfigChanges()
    {
        if(this.materialsMapChanges)
        {
            for(let mm of this.materialsMapChanges.values())
            {
                this.materialsMap.set(mm.key, mm)
            }            

            this.materialsMapChanges = undefined;
        }
    }
*/

    updateSolution(sol)
    {
        var material = this.materialsMap.get(sol.material)
        sol.update(material);
        this.summary.totalCost += sol.cost
        this.summary.totalMass += sol.value
    }

    addSolution(solutionValue)
    {
        this.updateSolution(solutionValue);

        this.solution.push(solutionValue);
        var mm = this.solutionByMaterial.get(solutionValue.material);
        if(!mm)
        {
            mm = [];
            this.solutionByMaterial.set(solutionValue.material, mm);
        }
        mm.push(solutionValue);

        mm = this.solutionByTarget.get(solutionValue.target);
        if(!mm)
        {
            mm = [];
            this.solutionByTarget.set(solutionValue.target, mm);
        }
        mm.push(solutionValue);

/*
        if(this.materialsMapChanges === undefined)
        {
            this.materialsMapChanges = new Map();
            for(let mm of this.materialsMap.values())
            {
                let cc = mm.copyMe();  
                this.materialsMapChanges.set(cc.key, cc);
            }            
        }
*/
    }


    hasConfigChanges()
    {
        let ret = false;
        if(this.materialsMapChanges)
        {
            for(let mm of this.materialsMap.values())
            {
                if(!ret)
                {
                    const nn = this.materialsMapChanges.get(mm.key);
                    if(nn)
                    {
                        ret = mm.hasDiffererences(nn);
                    }
                }
            }            
        }
        return ret;
    }

    hasMaterialConfigChanges(key)
    {
        let ret = false;
        if(this.materialsMapChanges)
        {
            const nn = this.materialsMapChanges.get(key);
            if(nn)
            {
                const mm = this.materialsMap.get(key);
                ret = nn.hasDiffererences(mm);
            }
        }
        return ret;
    }

    getTarget(key)
    {
        return this.targetsMap.get(key);
    }

    getTargets()
    {
        return this.targetsMap.values();
    }

    addTarget(target)
    {
        this.targetsMap.set(target.key, target);
    }

    removeTarget(target)
    {
        this.targetsMap.remove(target.key)
    }

    addMaterial(material)
    {
        this.materialsMap.set(material.key, material)
    }

    getMaterial(key)
    {
        return this.materialsMap.get(key);
    }

    getMaterialChanges(key)
    {
        if(this.materialsMapChanges === undefined)
        {
            this.materialsMapChanges = new Map();
        }
        let ret = this.materialsMapChanges.get(key);
        if(!ret)
        {
            const org = this.materialsMap.get(key);
            if(org)
            {
                ret = org.copyMe();
                this.materialsMapChanges.set(ret.key, ret);    
            }
        }

        return ret;
    }

/*
    getMaterialChanges(key)
    {
        return this.materialsMapChanges.get(key);
    }
*/

    resetMaterialChanges(key)
    {
        let org = this.materialsMap.get(key);
        if(org)
        {
            this.materialsMapChanges.set(key, org.copyMe());
        }
        else
        {
            this.materialsMapChanges.remove(key);
        }
        return 
    }

    getMaterials()
    {
        return this.materialsMap.values();
    }

    removeMaterial(material)
    {
        this.materialsMap.remove(material.key)
    }

    getTargetsAsRows()
    {
        var rows = [];
        this.targetsMap.forEach(function(tgt)
        {
            rows.push(tgt.getAsRow())
        })
        return rows;
    }

    getMaterialsAsRows()
    {
        var rows = [];
        this.materialsMap.forEach(function(tgt)
        {
            rows.push(tgt.getAsRow())
        })
        return rows;
    }

    getSolutionAsRows()
    {
        return this.solution;
    }

    getSolutionByTargetAsRows()
    {
        var ret = [];
        this.solutionByTarget.forEach((value, key) => 
        {    
            var target = this.targetsMap.get(key)
            var tgt = { 
                        targetKey: target.key, 
                        target: target.properties.type, 
                        key: key,
                        price: 0, 
                        mass: 0,
                        frac: 0,
                        fracPrice: 0,
                        sub: [] 
                    }

            var analysisAbsMin = {key: "Min"};
            var analysisAbsVal = {key: "Wert"};
            var analysisAbsMax = {key: "Max"};
            var analysisRelMin = {key: "Min"};
            var analysisRelVal = {key: "Wert"};
            var analysisRelMax = {key: "Max"};

            for(var kkk in SpecService.getElements())
            {
                analysisAbsMin[kkk] = undefined;
                analysisAbsVal[kkk] = undefined;
                analysisAbsMax[kkk] = undefined;
                analysisRelMin[kkk] = undefined;
                analysisRelVal[kkk] = undefined;
                analysisRelMax[kkk] = undefined;
            }

            for(var kkk in SpecService.getElements())
            {
                if(target.analysisMin.values[kkk] !== undefined)
                {
                    analysisRelMin[kkk] = target.analysisMin.values[kkk];
                    analysisAbsVal[kkk] = 0.0;
                }

                if(target.analysisMax.values[kkk] !== undefined)
                {
                    analysisRelMax[kkk] = target.analysisMax.values[kkk];
                    analysisAbsVal[kkk] = 0.0;
                }
            }

            for(var pp of value)
            {
                var mm = this.materialsMap.get(pp.material)
                tgt.mass += pp.value
                tgt.price += mm.properties.price * pp.value
            }

            for(var pp of value)
            {
                var mm = this.materialsMap.get(pp.material)

                for(var kkk in SpecService.getElements())
                {
                    if(mm.analysis.values[kkk] !== undefined)
                    {
                        if(analysisAbsVal[kkk] !== undefined)
                        {
                            analysisAbsVal[kkk] += mm.analysis.values[kkk] * pp.value
                        }                        
                    }
                }
                var normalVal = 0.0
                if(Math.abs(tgt.mass) > 1e-8)
                {
                    normalVal += (pp.value / tgt.mass)
                }
                else
                {
                    normalVal = 0.0;
                }
                tgt.frac += normalVal;

                tgt.fracPrice += ((mm.properties.price * pp.value) / tgt.price)
                tgt.sub.push({
                                material: pp.materialName, 
                                key: pp.material,
                                mass: pp.value, 
                                price: mm.properties.price * pp.value,
                                frac: normalVal,
                                fracPrice: (mm.properties.price * pp.value) / tgt.price
                            })
            }

            for(var kkk in SpecService.getElements())
            {
                if(analysisRelMin[kkk] !== undefined)
                {
                    analysisAbsMin[kkk] = analysisRelMin[kkk] * tgt.mass
                }
                if(analysisRelMax[kkk] !== undefined)
                {
                    analysisAbsMax[kkk] = analysisRelMax[kkk] * tgt.mass
                }
                if(analysisAbsVal[kkk] !== undefined)
                {
                    if(Math.abs(tgt.mass) > 1e-8)
                    {
                        analysisRelVal[kkk] = analysisAbsVal[kkk] / tgt.mass
                    }
                    else
                    {
                        analysisRelVal[kkk] = 0.0
                    }
                }
            }

            tgt.analysisRel = [analysisRelMin, analysisRelVal, analysisRelMax];
            tgt.analysisAbs = [analysisAbsMin, analysisAbsVal, analysisAbsMax];


            ret.push(tgt)
        })

        return ret;
    }

    getSolutionByMaterialAsRows()
    {
        var ret = [];
        this.solutionByMaterial.forEach((value, key) => 
        {
            var material = this.materialsMap.get(key)
            var tpy = {
                        material: material.properties.type, 
                        key: material.key,
                        price: 0, 
                        mass: 0,
                        frac: 0, 
                        fracPrice: 0,
                        sub: []
                    }

            for(var pp of value)
            {
                tpy.mass += pp.value
                var c = material.properties.price
                tpy.price += c * pp.value
            }

            tpy.frac = tpy.mass / material.properties.mass_max;
            tpy.fracPrice = tpy.price / (material.properties.price * material.properties.mass_max);

            for(var pp of value)
            {
                var c = material.properties.price
                tpy.price += c * pp.value
                tpy.sub.push({
                                target: pp.targetName, 
                                key: pp.target,
                                mass: pp.value, 
                                price: c * pp.value,
                                frac: pp.value / material.properties.mass_max,
                                fracPrice: pp.value / material.properties.mass_max
                            })
            }

            if(Math.abs(material.properties.mass_max - tpy.mass) > 1-2)
            {
                tpy.leftovers = {
                    target: '-',
                    key: -1,
                    mass: material.properties.mass_max - tpy.mass, 
                    price: 0.0,
                    frac: (material.properties.mass_max - tpy.mass) / material.properties.mass_max,
                    fracPrice: (material.properties.mass_max - tpy.mass) / material.properties.mass_max
                }
            }
    
            ret.push(tpy)
    
        })

        return ret;
    }


    toOpimizeJSON()
    {
        var order = { uid: this.key }
        
        order.targets = []
        this.targetsMap.forEach(function(value)
        {
//            if(value.useInOptimization)
            if(value.getUseInOptimization())
            {
                var analysis = [];
                for(var kkk in SpecService.getElements())
                {
                    analysis.push({
                        element: kkk.toUpperCase(),
                        min: value.analysisMin.values[kkk],
                        max: value.analysisMax.values[kkk]
                    })
                }
                var bla = Object.assign({}, value.properties);
                bla.analysis = analysis;
                bla.key = value.key;
    
                order.targets.push(bla)
            }
        })

        order.materials = []
        for(var value of this.materialsMap.values())
        {
            const twp = value.getOptimizationTotalWeightProperties();

            if(value.useInOptimization)
            {
                var analysis = []
                for(var kkk in SpecService.getElements())
                {
                    analysis.push({
                        element: kkk.toUpperCase(),
                        value: value.analysis.values[kkk]
                    })
                }

                var bla = Object.assign({}, value.properties)
                bla.analysis = analysis;
                bla.key = value.key;

                if(twp.enabled)
                {
                    bla.mass_max = twp.maxWeight;
                    if(twp.semiLinMinWeight == undefined)
                    {
                        bla.mass_min = twp.minWeight;
                        bla.minSemiLinear = false;
                    }
                    else if(twp.semiLinMinWeight > 1e-2)
                    {
                        bla.mass_min = twp.minWeight;
                        bla.minSemiLinear = true;
                    }
                    else
                    {
                        bla.mass_min = twp.minWeight;
                        bla.minSemiLinear = false;
                    }
                }

                const ptc = value.getOptimizationPerTargetCommonWeightProperties();
                bla.minWeightPerTarget = [];
                for(var tgt of this.targetsMap.values())
                {
                    let pwp = ptc;
                    if(!pwp.enabled)
                    {
                        pwp = value.getOptimizationPerTargetWeightProperties(tgt.key, tgt.properties.type)
                    }

                    if(pwp.enabled)
                    {
                        if(pwp.semiLinMinWeight > 1e-2)
                        {
                            bla.minWeightPerTarget.push({
                                key: tgt.key, 
                                mass_min: pwp.semiLinMinWeight,
                                mass_max: pwp.maxWeight,
                                semiLinear: true
                            })
                        }
                        else
                        {
                            bla.minWeightPerTarget.push({
                                key: tgt.key, 
                                mass_min: pwp.minWeight,
                                mass_max: pwp.maxWeight,
                                semiLinear: false
                            })
                        }
                    }
                }
                
                order.materials.push(bla)
            }
        }

        return JSON.stringify(order)
    }


}