<template>

        <Toast/>

        <DataTable :value="content.getRows()" :scrollable="true" scrollDirection="both" scrollHeight="400px">
            <template #header>
                <div class="table-header">

                    <p> Importieren als ... </p>

                </div>
            </template>

            <ColumnGroup type="header">

                <Row>
                    <Column :key="rowkey"
                            :rowspan="1"
                            :colspan="1"
                             style="width:8ch">
                    </Column>
                    <Column  v-for="col of content.columns" 
                            :key="col.field"
                            :rowspan="1"
                            :colspan="1">
                        <template #header>
                            <div class="multiheader">
                                <div class="subheader">
                                    {{col.header}}
                                </div>
                            </div>
                        </template>
                    </Column>        
                </Row>

                <Row>
                    <Column :key="rowkey"
                            :rowspan="1"
                            :colspan="1"
                             style="width:8ch">
                        <template #header>
                            <div class="multiheader">
                                <div class="subheader1">
                                    1
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column  v-for="col of content.columns" 
                            :key="col.field"
                            :rowspan="1"
                            :colspan="1">
                        <template #header>
                            <div class="multiheader">
                                <div class="subheader">
                                    {{col.firstrow}}
                                </div>                        
                            </div>
                        </template>

                    </Column>        
                </Row>


                <!-- Materials Row -->
                <Row v-if="hasCustomColumns()">
                    <Column :key="rowkey"
                            :rowspan="1"
                            :colspan="1"
                             style="width:8ch">
                    </Column>
                    <Column  v-for="col of content.columns" 
                            :key="col.field"
                            :rowspan="1"
                            :colspan="1">
                        <template #header>
                            <div class="multiheader">
                                <div class="subheader">
                                    <ToggleButton  v-model="columnChecked[col.field]" 
                                                   onIcon="pi pi-check" 
                                                   offIcon="pi pi-times" 
                                                  @change="onToggelChange"/>
                                    <Dropdown  v-model="matchedColumns[col.field]" 
                                              :options="columnMatchOptions" 
                                               optionLabel="name" 
                                               optionName="id"
                                               placeholder="Select..."
                                              @change="onDropChange"/>
                                </div>                        
                            </div>
                        </template>
                    </Column>        
                </Row>
            </ColumnGroup>

            <Column :key="rowkey"
                    :field="rowkey"
                    :style="{width:'8ch',padding:0}">
                <template #body="slotProps">
                    <div class="font-bold my-rownumber">
                        {{slotProps.data[rowkey]}}
                    </div>
                </template>
            </Column>

            <Column  v-for="col of content.columns" 
                    :field="col.field" 
                    :key="col.field"
                    :style="col.style">

                <template #body="slotProps">
                    <div :class="selectClass(columnChecked[col.field])">
                        {{slotProps.data[col.field]}}
                    </div>
                </template>


            </Column>

            <template #footer>
                <div class="table-footer">
                    <p v-if="isMaterials"> MATERIALS </p>
                    <p v-if="isTargets"> TARGETS </p>
                    <Button  label="Import" 
                            :disabled="importDisabled" 
                            :badge="importCount"
                            @click="write2store"/>
                </div>
            </template>

        </DataTable>

</template>
<script>

import ExcelTableData from '../../service/ExcelTableData'
import ExcelTableUtils from '../../service/ExcelTableUtils'

export default 
{
    props: 
    {
        content: Object,
        filename: String,
        sheetname: String,
        mode: String
    },

    emits: ['xlsimport'],

    previousColumnChecked: null,

    data() 
    {
        return {
            selected: null,
            isMaterials: false,
            isTargets: false,
            isTargetsUpdate: false,
            columnMatchOptions: null,
            matchedColumns: [],
            assigned: [
                {name: "bla1", code: "b1"},
                {name: "bla2", code: "b2"}
            ],
            columnChecked: [],
            rowkey: ExcelTableData.ROW_KEY,
            importDisabled: true,
            importCount: null
        };
    },

    watch:
    {
        content: function(newContent, oldContent)
        {
            this.onChange();
        }
    },

    created() 
    {
            this.onChange();
    },

    methods: 
    {
        onChange()
        {
            if("materials" === this.mode) 
            {       
                this.isMaterials = true;
                this.isTargets = false;
                this.isTargetsUpdate = false;
                this.columnMatchOptions = ExcelTableData.createMaterialsOptions();
                this.matchedColumns = this.content.getMaterialsOptionsDefaultMatch();
                this.columnChecked = [];
                this.previousColumnChecked = [];
                for(var ff in this.matchedColumns)
                {
                    this.columnChecked[ff] = true;
                    this.previousColumnChecked[ff] = true;
                }
            } 
            else if("target" === this.mode) 
            {
                this.isMaterials = false;
                this.isTargets = true;
                this.isTargetsUpdate = false;
                this.columnMatchOptions = ExcelTableData.createTargetsOptions();
                this.matchedColumns = this.content.getTargetsOptionsDefaultMatch();
                this.columnChecked = [];
                this.previousColumnChecked = [];
                for(var ff in this.matchedColumns)
                {
                    this.columnChecked[ff] = true;
                    this.previousColumnChecked[ff] = true;
                }
            } 
            else if("targetupdate" === this.mode) 
            {
                this.isMaterials = false;
                this.isTargets = false;
                this.isTargetsUpdate = true;
                this.columnMatchOptions = ExcelTableData.createTargetsUpdateOptions(); 
                this.matchedColumns = this.content.getTargetsUpdateOptionsDefaultMatch();  
                this.columnChecked = [];
                this.previousColumnChecked = [];
                for(var ff in this.matchedColumns)
                {
                    this.columnChecked[ff] = true;
                    this.previousColumnChecked[ff] = true;
                }
            } 
            else 
            {
                console.log("Should not be here!")
            }

            this.updateImportButton();   
        },

        hasCustomColumns()
        {
            return this.isMaterials || this.isTargets
        },

        onDropChange(event)
        {
            for(var cc in this.matchedColumns)
            {
                if(this.matchedColumns[cc] !== null)
                {
                    this.columnChecked[cc] = true;
                }
                else
                {
                    this.columnChecked[cc] = false;
                }
                this.previousColumnChecked[cc] = this.columnChecked[cc];
            }

            this.updateImportButton();
        },

        getIdColumNumber()
        {
            var ret = null
            for(var zz in this.matchedColumns)
            {
                var xx = this.matchedColumns[zz];
                if(xx != null)
                {
                    if(xx.id == this.columnMatchOptions[0].id)
                    {
                        if(ret != null)
                        {
                            // multiple id columns -> no import
                            return null;
                        }
                        ret = zz;
                    }
                }
            }
            return ret;
        },

        onToggelChange(event)
        {
            for(var cc in this.columnChecked)
            {
                if(this.columnChecked[cc] != this.previousColumnChecked[cc])
                {
                    if(this.columnChecked[cc])
                    {
                        this.matchedColumns[cc] = this.columnMatchOptions[0];
                    }
                    else
                    {
                        this.matchedColumns[cc] = null;
                    }
                    this.previousColumnChecked[cc] = this.columnChecked[cc];
                }
            }

            this.updateImportButton();
        },

        selectClass(cc)
        {
            var val = false;
            if(cc)
            {
                val = true;
            }
            return [
                {
                    'columnOn': val,
                    'columnOff': !val
                 }
            ];
        },

        updateImportButton()
        {
            var idCol = this.getIdColumNumber();
            if(idCol != null)
            {
                this.importCount = "" + this.content.getNonEmptyCount(idCol);
                this.importDisabled = false;
            }
            else
            {
                this.importCount = null;
                this.importDisabled = true;
            }                    
        },

        write2store()
        {
            if(this.isMaterials) 
            {
                ExcelTableUtils.writeMaterials2store(this.content, 
                                                     this.matchedColumns, 
                                                     this.filename,
                                                     this.sheetname,
                                                     this.$store)

                this.$toast.add({severity: 'info', summary: 'Materials Added', life: 2000});
//                this.$emit("xlsimport", "success")
                this.emitXLSImportEvent("materials", true)
            }
            else if(this.isTargets) 
            {
                ExcelTableUtils.writeTargets2store(this.content, 
                                                   this.matchedColumns, 
                                                   this.filename,
                                                   this.sheetname,
                                                   this.$store)

                this.$toast.add({severity: 'info', summary: 'Targets Added', life: 3000});
  //              this.$emit("xlsimport", "success")
                this.emitXLSImportEvent("targets", true)
            }
            else if(this.isTargetsUpdate) 
            {
                ExcelTableUtils.writeTargets2store(this.content, 
                                                   this.matchedColumns, 
                                                   this.filename,
                                                   this.sheetname,
                                                   this.$store)

                this.$toast.add({severity: 'info', summary: 'Targets Update Added', life: 3000});
                this.emitXLSImportEvent("targetsUpdate", true)
            }

        },

        emitXLSImportEvent(type, success)
        {
            this.$emit("xlsimport", {type: type, success: success});
        }
    }

};
</script>

<style scoped>
div.multiheader {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}
div.subheader {
    padding: 2;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
div.my-rownumber {
    background-color: var(--surface-ground);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.p-dropdown {
    width: 90%;
}
.columnOn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.columnOff {
    color: var(--gray-300);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>