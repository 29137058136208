<template>
    <div  ref="root"
         :style="divstyle">
    </div>
</template>

<script>


export default {
    name: 'HelloPlyParCoords',
  
    inject: ['Plotly'],

    props: 
    {
        pdata: Array,
        width: String,
        modebar: {
            type: Boolean,
            default: false
        }
    },

    created() 
    {
        this.utils = this.$store.state.utils;
        this.pconfig.displayModeBar = this.modebar;
    },

    data() {
        return {
            playout: [],
            pconfig: { displayModeBar: false }
        }
    },

    mounted() 
    {
        this.playout = this.getLayout();
        this.plotly();
    },

    computed: {
        divstyle: function (){
                return {
                    width: this.width,
                    height: '250px',
                    backgroundColor: 'grey'
                }
            },
    },

    methods: 
    {
        plotly()
        {
            this.Plotly.newPlot( this.$refs.root, 
                                 this.pdata, 
                                 this.playout,
                                 this.pconfig);
        },

        getPlotlyWidth()
        {
            return this.$refs.root.clientWidth;
        },

        getPlotlyHeight()
        {
            return this.$refs.root.clientHeight;
        },

        getLayout()
        {
            return {
                autoresize: true,
                height: this.getPlotlyHeight(),
                width: this.getPlotlyWidth(),
                title: "",
                showlegend: false,
                margin: 
                     {
                        l: 50,
                        r: 5,
                        b: 30,
                        t: 5,
                        pad: 5
                    },
                autosize: true,
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
</style>
