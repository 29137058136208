import { createStore } from 'vuex'
import Utils from '../service/Utils';

export default createStore(
{
  state: 
  {
//    solution: null,
    utils: new Utils(),

    excelsheets: new Map(),
    excelfiles: new Map(),

    orderBlockVariants: new Map(),
    nextOrderBlockVariant: null,
    rootOrderBlockVariant: null,

    targetsImportedCount: 0
  },

  mutations: 
  {
/*
    solution(state, solution)
    {
      state.solution = solution;
    },
*/  
/*  
    sheet(state, exlsData)
    {
      state.exclsheets.set(exlsData.getId(), exlsData);
    },
*/    
    orderBlock(state, orderBlock)
    {
      if(orderBlock.key === undefined)
      {
        orderBlock.key = state.orderBlockVariants.size;
      }
      state.currentOrderBlockVariant = orderBlock;
      if(!state.rootOrderBlockVariant)
      {
        state.rootOrderBlockVariant = orderBlock;
      }
      state.orderBlockVariants.set(orderBlock.key, orderBlock)
    }

  },

  actions: 
  {
  },

  modules: 
  {
  }
})
