<template>

    <div v-if="orderBlock">
        <Card>
            
            <!--
            <template #header>
                Aktuelle Variante: {{currentOrderBlockName}}
            </template>
            -->

            <template #title>
                Ergebnisse
            </template>
            <template #subtitle>
                <div v-if="hasSolution">
                    Ergebnisse liegen vor
                </div>
                <div v-else>
                    Noch keine Ergebnisse berechnet
                </div>
            </template>

            <template #content>
                
                <div v-if="hasSolution">
                    <ResultsTable :solution="solution"
                                  :solutionByTarget="solutionByTarget"
                                  :solutionByType="solutionByMaterial"
                                  :summary="summary"/>
                    <Divider/>
                    <Sankey width='100%' :solution="solution"/>
                </div>

            </template>

            <!--
            <template #footer>
                Aktuelle Variante: {{currentOrderBlockName}}
            </template>
            -->
        </Card>
    </div>

</template>

<script>

import ResultsTable from "../subcomponents/MBResultsTable.vue"
import Sankey from "../charts/chartjs/MBChartjsSankey.vue"

export default {

    components: 
    {
        ResultsTable,
        Sankey
    },

    data() 
    {
        return {
            expandedRowsByType: [],
            expandedRowsByTarget: [],
            showElementsPercent: true,
            showElementsPercentLabel: '%'
        }
    },

    computed: 
    {
        orderBlock()
        {
            return this.$store.state.currentOrderBlockVariant;
        },

        currentOrderBlockName()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.name + " [" + this.orderBlock.key + "]";
            }
            return undefined;
        },

        hasSolution()
        {
            return this.solution.length > 0;
        },

        solution()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.getSolutionAsRows();
            }
            else
            {
                return [];
            }
        },

        solutionByTarget()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.getSolutionByTargetAsRows();
            }
            else
            {
                return [];
            }
        },

        solutionByMaterial()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.getSolutionByMaterialAsRows();
            }
            else
            {
                return [];
            }
        },

        summary()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.summary;
            }
            else
            {
                return [];
            }
        }
    },

    created() 
    {
        this.utils = this.$store.state.utils;
    },

    methods: 
    {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>