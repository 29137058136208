<template>
    <div  ref="root0" 
         :style="divstyle">

        <Chart  type="sankey" 
               :data="chartData" 
               :options="options"/>

    </div>
</template>

<script>

//import SpecService from "../../../service/SpecService"
//import StyleService from "../../../service/Styleservice"

//import {Chart} from 'chart.js';
import {Chart} from 'chart.js';
import {SankeyController, Flow} from 'chartjs-chart-sankey';
Chart.register(SankeyController, Flow);

export default 
{
    name: 'MBParCoords',
  
    props: 
    {
        solution: Object,
        width: String
    },

    computed:
    {
        rootDivWidth()
        {
            return this.$refs.root0.clientWidth;
        },

        rootDivHeight()
        {
            return this.$refs.root0.clientHeight;
        }

    },

    created() 
    {
        this.utils = this.$store.state.utils;        
    },

    data() 
    {
        return {
            normalize: true
        }
    },

    mounted() 
    {
    },

    computed: 
    {
        divstyle: function ()
        {                
            return {
                position: 'relative',
                width: this.width,
                //height: '550px',
                //backgroundColor: 'grey'
            }
        },

        chartData()
        {
            var data = [];
            for(var sol of this.solution)
            {
                if(Math.abs(sol.value) > 1e-4)
                {
                    data.push({
                        from: sol.materialName + " [M]",
                        to: sol.targetName + " [Z]",
                        flow: sol.value  
                    })
                }
            }

            var ret = {
                datasets: [{
                    label: 'My sankey',
                    data: data,
                    colorFrom: (c) => this.getColor(c.dataset.data[c.dataIndex].from),
                    colorTo: (c) => this.getColor(c.dataset.data[c.dataIndex].to),
                    colorMode: 'gradient', // or 'from' or 'to'
                    /* optional labels */
                    /*
                    labels: {
                        a: 'Label A',
                        b: 'Label B',
                        c: 'Label C'
                    },
                    */
                    /* optional priority */
                    /*
                    priority: {
                        a: 0,
                        b: 1,
                        c: 2
                    },
                    */
                    size: 'max', // or 'min' if flow overlap is preferred
                }]
            }

            return ret
        },


        options()
        {
            return {
                responsive: true,
//                maintainAspectRatio: false,
                maintainAspectRatio: true,
                plugins: 
                {
                },
            }
        },

    },

    methods: 
    {
        getColor(ccc)
        {
            return this.utils.getRandomColorRGB(ccc);
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
</style>
