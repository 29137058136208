export default class Target
{
    static Properties = 
    {
        type: 
        {
            id: "type",
            label: "ART",
            alias: ["name", "ziel", "Art"],
            required: true,
            requiredOnUpdate: true,
            updatable: true,
            numeric: false
        },
        mass_min: 
        {
            id: "mass_min",
            label: "Menge Min",
            alias: ["min"],
            required: false,
            requiredOnUpdate: true,
            updatable: true,
            numeric: true
        },
        mass_max: 
        {
            id: "mass_max",
            label: "Menge Max",
            alias: ["max"],
            required: false,
            requiredOnUpdate: false,
            updatable: true,
            numeric: true
        }
    }


    constructor(key, dataSource, analysisMin, analysisMax)
    {
        this.key = key;
        this.dataSource = dataSource;
        this.analysisMin = analysisMin;
        this.analysisMax = analysisMax;

        //this.useInOptimization = true;
        this.useInOptimizationFlag = true;
        this.properties = {}

        for(var pp in Target.Properties)
        {
            this.properties[pp] = undefined
        }
    }

    getUseInOptimization()
    {
        if(this.properties.mass_min)
        {
            if(this.properties.mass_min > 0)
            {
                return this.useInOptimizationFlag;
            }
        }
        return false;
    }

    setUseInOptimization(flag)
    {
        this.useInOptimizationFlag = flag
        return this.getUseInOptimization()
    }

    copyMe()
    {
        var ret = new Target(this.key, this.dataSource, this.analysisMin, this.analysisMax);
        ret.properties = {... this.properties};
        return ret;
    }


    setProperty(id, value)
    {
        var prop = Target.Properties[id];
        if(prop)
        {
            if(prop.numeric && value !== undefined)
            {
                this.properties[id] = parseFloat(value);
            }
            else
            {
                this.properties[id] = value;
            }
        }
        else
        {
            console.trace();
            throw "Unknown property: '" + id + "'";
        }
    }

    isUpdatable()
    {
        for(let zz in Target.Properties)
        {
            if(!Target.Properties[zz].required && Target.Properties[zz].updatable)
            {
                if(this.properties[zz] !== undefined)
                {
                    console.log("Cannot update: " + this.properties[zz]);
                    return false;
                }
            }
        }
        return true;
    }

    overrideUpdatable(other)
    {
        for(let zz in Target.Properties)
        {
            if(Target.Properties[zz].updatable)
            {
                this.properties[zz] = other.properties[zz];
            }
        }
    }

    overrideNonUpdatable(other)
    {
        for(let zz in Target.Properties)
        {
            if(!Target.Properties[zz].updatable)
            {
                this.properties[zz] = other.properties[zz];
            }
        }

        this.analysisMin = other.analysisMin;
        this.analysisMax = other.analysisMax;
    }


    getName()
    {
        return this.properties.type;
    }

    getAsRow()
    {
        var ret = Object.assign({}, this.properties);
//        ret.useInOptimization = this.useInOptimization;
        ret.useInOptimization = this.getUseInOptimization();
        ret.key = this.key;
        ret.analysis = [this.analysisMin.getAsRow(), 
                        this.analysisMax.getAsRow()];
        ret.analysis[0].key = "Min";
        ret.analysis[1].key = "Max";

        return ret;
    }
}