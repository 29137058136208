<template>

    <MaterialChangeDialog :display="materialchangedisplay" 
                          :candidate="materialchangecandidate"
                          @matdispclose="onMatdispclose"/>

    <DataTable :value="rows" 
                responsiveLayout="scroll"
                v-model:expandedRows="expandedRows" 
                dataKey="key"
                removableSort
                sortMode="multiple"
               :multiSortMeta="multiSortMeta"
               @rowExpand="onRowExpand" 
               @rowCollapse="onRowCollapse">

        <template #header>
            <SelectButton  v-model="pivotSelected" 
                          :options="pivotOptions" 
                          :multiple="false"
                           optionLabel="label"
                           optionValue="value"
                          @change="onPivotChange" />
        </template>

        <Column :expander="hasExpander" 
                 headerStyle="width: 3rem" />
        
        <Column :hidden="!hasTargetColumn"
                :field="targetField" 
                 header="Ziel"
                :sortable="true">
            <template #footer>
                Summe
            </template>
        </Column>

        <Column :hidden="!hasTypeColumn"
                :field="materialField" 
                 header="Material"
                :sortable="true">
            <template #footer>
                Summe
            </template>
        </Column>

        <Column :field="priceField" 
                 header="Kosten"
                 dataType="numeric"
               :sortable="true">
            <template #body="sp">
                <div :style="styleNumber(StyleService.formatCurrency(summary.totalCost))">
                    {{StyleService.formatCurrency(sp.data[priceField])}}
                </div>
            </template>
            <template #footer>
                <div :style="styleNumber(StyleService.formatCurrency(summary.totalCost))">
                    {{StyleService.formatCurrency(summary.totalCost)}}
                </div>
            </template>
        </Column>

        <Column :field="massField" 
                 header="Menge"
                 dataType="numeric"
                :sortable="true">
            <template #body="sp">
                <!-- <div  v-if="masseditDisabled"
                     :style="styleNumber(StyleService.formatDecimal(summary.totalMass, 'kg'))"> -->
                <div  v-if="masseditDisabled"
                     :style="getFeasibleInfeasibleStyle(sp.data, summary.totalMass)">
                    {{StyleService.formatDecimal(sp.data[massField], 'kg')}}
                </div>
                <div  v-else-if="hasConfigChanges(sp.data)"
                     :style="styleNumber(StyleService.formatDecimal(summary.totalMass, 'kg*'))">

                    <Button :label="StyleService.formatDecimal(sp.data[massField], 'kg*')" 
                             class="p-button-warning p-button-text" 
                             @click="onMassEditClick(sp.data)"/>

                </div>
                <div  v-else
                     :style="styleNumber(StyleService.formatDecimal(summary.totalMass, 'kg'))">

                    <Button :label="StyleService.formatDecimal(sp.data[massField], 'kg')" 
                             class="p-button-text" 
                             @click="onMassEditClick(sp.data)"/>

                </div>

            </template>

            <template #footer>
                <div :style="styleNumber(StyleService.formatDecimal(summary.totalMass, 'kg'))">
                    {{StyleService.formatDecimal(summary.totalMass, 'kg')}}
                </div>
            </template>
        </Column>

        <template #expansion="slotProps">
            <Card :style="StyleService.getSubTableCardStyle()">
                <template #header>
                    header
                </template>
                <template #title>
                    Sub for {{slotProps.data[pivot]}}
                </template>
                <template #content>

                    <MassCostTable :totals="slotProps.data"
                                   :rows="slotProps.data.sub"
                                   :keyLabelColumn="subKeyLabelColumn"
                                   :label="slotProps.data[pivot] + ''"/>

                    <ElementsTable v-if="showAnalysis"
                                  :analysisRel="slotProps.data.analysisRel"
                                  :analysisAbs="slotProps.data.analysisAbs"/>

                </template>

                <template #footer>
                    footer
                </template>

            </Card>
        </template>

    </DataTable>


</template>

<script>

import StyleService from "../../service/Styleservice.js"
import MassCostTable from "./MBMassCostTable.vue"
import ElementsTable from "./MBElementsTable.vue"

import MaterialChangeDialog from '../simplemix/MaterialChangeDialog.vue'

export default {

    components: 
    {
        MassCostTable,
        ElementsTable,
        MaterialChangeDialog
    },

    props: 
    {
        solution: Array,
        solutionByTarget: Array,
        solutionByType: Array,
        summary: Object
    },

    data() 
    {
        return {
            pivot: 'target',
            subKeyLabelColumn: 'material',
            hasTargetColumn: false,
            hasTypeColumn: false,
            hasExpander: false,
            expandedRows: [],
            priceField: null,
            massField: null,
            pivotSelected: null,
            pivotOptions: [
                {label: 'Ziel', value: 'target'},
                {label: 'Material', value: 'material'},
            ],
            showAnalysis: false,
            materialField: 'material',
            targetField: 'target',
            masseditDisabled: false,
            materialchangedisplay: false,
            materialchangecandidate: undefined,
            materialKey: undefined,
            multiSortMeta: []
        }
    },

    computed: 
    {
        rows()
        {
            if(this.pivot == 'target')
            {
                this.subKeyLabelColumn = 'material';
                this.hasTargetColumn = true;
                this.hasTypeColumn = false;
                this.hasExpander = true;
                this.priceField = "price";
                this.massField = "mass";
                this.pivotSelected = this.pivot
                this.showAnalysis = true;
                this.materialField = 'material';
                this.targetField = 'target';
                this.masseditDisabled = true;
                this.materialKey = undefined;
                this.multiSortMeta =  [
                    {field: 'price', order: -1}
                ]
                return this.solutionByTarget;
            }
            else if(this.pivot == 'material')
            {
                this.subKeyLabelColumn = 'target';
                this.hasTargetColumn = false;
                this.hasTypeColumn = true;
                this.hasExpander = true;
                this.priceField = "price";
                this.massField = "mass";
                this.pivotSelected = this.pivot
                this.showAnalysis = false;
                this.materialField = 'material';
                this.targetField = 'target';
                this.masseditDisabled = false;
                this.materialKey = 'key';
                this.multiSortMeta =  [
                    {field: 'price', order: -1}
                ]
                return this.solutionByType;
            }
            else
            {
                this.subKeyLabelColumn = undefined;
                this.hasTargetColumn = true;
                this.hasTypeColumn = true;
                this.hasExpander = false;
                this.priceField = "cost";
                this.massField = "value";
                this.pivotSelected = [];
                this.showAnalysis = false;
                this.materialField = 'materialName';
                this.targetField = 'targetName';
                this.masseditDisabled = false;
                this.materialKey = 'material';
                this.multiSortMeta =  [
                    {field: 'cost', order: -1}
                ]
                return this.solution;
            }
        }
    },

    created() 
    {
        this.StyleService = StyleService;
    },

    methods: 
    {
        onMatdispclose(evt)
        {
            this.materialchangedisplay = false;
            this.materialchangecandidate = undefined;
        },

        onPivotChange(event)
        {
            if(this.pivot == event.value)
            {
                this.pivot = "all";
                this.pivotSelected = [];
            }
            else
            {
                this.pivot = event.value;
            }
        },

        onRowExpand(event) 
        {
            //this.$toast.add({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
        },

        onRowCollapse(event) 
        {
            //this.$toast.add({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
        },

        hasConfigChanges(obj)
        {
            let ret = false;
            if(obj[this.materialKey])
            {
                ret = this.$store.state.currentOrderBlockVariant.hasMaterialConfigChanges(obj[this.materialKey]);
            }
            return ret;
        },

        onMassEditClick(obj)
        {
            let candidate = {};
            candidate.material = obj[this.materialKey];
            candidate.materialName = obj[this.materialField];
            candidate.targetName = obj[this.targetField];
            candidate.value = obj[this.massField];

//            this.materialchangecandidate = obj;
            this.materialchangecandidate = candidate;
            this.materialchangedisplay = true;
        },

        styleNumber(reference)
        {
            return {
                        width: reference.length + 'ch', 
                        textAlign: 'right', 
                        //backgroundColor: 'green'
                    }
        },

        styleInfeasibleNumber(reference)
        {
            return {
                        width: reference.length + 'ch', 
                        textAlign: 'right', 
                        backgroundColor: 'red'
                    }
        },

        getFeasibleInfeasibleStyle(obj, totalMass)
        {
            console.log(obj);
            if(obj.targetKey)
            {
                let tgt = this.$store.state.currentOrderBlockVariant.getTarget(obj.targetKey);
                //console.log(tgt)
                const mi = tgt.properties.mass_min;
                const ma = tgt.properties.mass_max;
                console.log(ma)
                console.log(obj.mass)
                if(obj.mass < mi - 1e-1 || obj.mass > ma + 1e-1)
                {
                    return this.styleInfeasibleNumber(StyleService.formatDecimal(totalMass, 'kg'))
                }
            }

            return this.styleNumber(StyleService.formatDecimal(totalMass, 'kg'))
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>