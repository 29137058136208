import { createApp } from 'vue'
import App from './App.vue'

import PrimeVue from 'primevue/config';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Chart from 'primevue/chart';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import SelectButton from 'primevue/selectbutton';
import ToggleButton from 'primevue/togglebutton';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import Tree from 'primevue/tree';
import ContextMenu from 'primevue/contextmenu';
import Checkbox from 'primevue/checkbox';
import Divider from 'primevue/divider';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import InputNumber from 'primevue/inputnumber';
import Badge from 'primevue/badge';
import Sidebar from 'primevue/sidebar';
import Toolbar from 'primevue/toolbar';
import ProgressSpinner from 'primevue/progressspinner';

import Tooltip from 'primevue/tooltip';

//import "primevue/resources/themes/nova-vue/theme.css";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
//import "primevue/resources/themes/md-light-deeppurple/theme.css";
//import "primevue/resources/themes/saga-orange/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import Plotly from "plotly.js/dist/plotly"


import store from './store'

import "primeflex/primeflex.css";
import router from './router'

const app = createApp(App).use(router);
app.use(store);
app.use(PrimeVue);
app.use(ToastService);


app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Row', Row);
app.component('ColumnGroup', ColumnGroup);
app.component('Card', Card);
app.component('InputText', InputText);
app.component('Button', Button);
app.component('Chart', Chart);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('SelectButton', SelectButton);
app.component('ToggleButton', ToggleButton);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Toast', Toast);
app.component('Dialog', Dialog);
app.component('Panel', Panel);
app.component('Tree', Tree);
app.component('ContextMenu', ContextMenu);
app.component('Checkbox', Checkbox);
app.component('Divider', Divider);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('InputNumber', InputNumber);
app.component('Badge', Badge);
app.component('Sidebar', Sidebar);
app.component('Toolbar', Toolbar);
app.component('ProgressSpinner', ProgressSpinner);

app.directive('tooltip', Tooltip);

app.provide("Plotly", Plotly)

app.mount('#app')
