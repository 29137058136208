<template>

<!--
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/login">Login</router-link>
  </nav>
-->
  <router-view/>

</template>

<style>



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: var(--surface-200);
  /*margin-top: 5px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  height:100vh;
  width:100vw;*/
}

body {
  background-color: var(--surface-200);
}

</style>
