<template>
    <div  ref="root0" 
         :style="divstyle">

        <Chart  type="line" 
               :data="chartData" 
               :options="options"/>

    </div>
</template>

<script>

import SpecService from "../../../service/SpecService"

export default 
{
    name: 'MBParCoords',
  
    props: 
    {
        analysisRel: Array,
        analysisAbs: Array,
        width: String
    },

    computed:
    {
        rootDivWidth()
        {
            console.log(this.$refs)
            return this.$refs.root0.clientWidth;
        },

        rootDivHeight()
        {
            return this.$refs.root0.clientHeight;
        }

    },

    created() 
    {
    },

    data() 
    {
        return {
            absValues: false,
            normalize: true
        }
    },

    mounted() 
    {
    },

    computed: 
    {
        divstyle: function ()
        {                
            return {
                position: 'relative',
                width: this.width,
                //height: '550px',
                //backgroundColor: 'grey'
            }
        },

        chartData()
        {
            var ret = {
                labels: SpecService.getSortedElementLabels(),
                datasets: this.datasets
            }

            return ret
        },

        datasets()
        {
            var aMin = null;
            var aVal = null;
            var aMax = null;
            if(this.absValues)
            {
                for(var ii=0; ii<this.analysisAbs.length; ii++)
                {
                    switch(this.analysisAbs[ii].key)
                    {
                    case "Min":
                        aMin=this.analysisAbs[ii];
                        break;
                    case "Max":
                        aMax=this.analysisAbs[ii];
                        break;
                    case "Wert":
                        aVal=this.analysisAbs[ii];
                        break;
                    }
                }
            }
            else
            {
                for(var ii=0; ii<this.analysisRel.length; ii++)
                {
                    switch(this.analysisRel[ii].key)
                    {
                    case "Min":
                        aMin=this.analysisRel[ii];
                        break;
                    case "Max":
                        aMax=this.analysisRel[ii];
                        break;
                    case "Wert":
                        aVal=this.analysisRel[ii];
                        break;
                    }
                }
            }

            var fillcol = "rgba(64,236,99,0.5)"
            var edgecol = "rgba(64,236,99,1.0)"

            var dmax = {
                label: 'Max',
                data: [],
                borderColor: edgecol,
                backgroundColor: edgecol,
                pointRadius: 0,
            }
            var dmin = {
                label: 'Min',
                data: [],
                borderColor: edgecol,
                backgroundColor: edgecol,
                pointRadius: 0,
                fill: {
                    target: '2',
                    above: fillcol,
                    below: fillcol
                }

            }
            var dval = {
                label: 'Wert',
                data: [],
                borderColor: 'black',
                backgroundColor: "black",
                pointRadius: 5,
            }
            
            for(var ee of SpecService.getSortedElementLabels())
            {
                if(this.normalize)
                {
                    /*
                    if(aMin[ee] !== undefined && aMax[ee] !== undefined)
                    {
                        dmax.data.push(1.0);
                        dmin.data.push(0.0);
                        if(Math.abs(aMax[ee] - aMin[ee]) > 1e-6)
                        {
                            dval.data.push((aVal[ee] - aMin[ee]) / (aMax[ee] - aMin[ee]));
                        }
                        else
                        {
                            dval.data.push(0.0);
                        }
                    }
                    */
                    if(aVal[ee] !== undefined)
                    {
                        dmax.data.push(1.0);
                        dmin.data.push(0.0);
                        if(Math.abs(aMax[ee] - aMin[ee]) > 1e-6)
                        {
                            dval.data.push((aVal[ee] - aMin[ee]) / (aMax[ee] - aMin[ee]));
                        }
                        else
                        {
                            dval.data.push(0.0);
                        }
                    }

                    else
                    {
                        dmax.data.push(undefined);
                        dmin.data.push(undefined);
                        dval.data.push(undefined);
                    }
                }
                else
                {
                    dmax.data.push(aMax[ee]);
                    dmin.data.push(aMin[ee]);
                    dval.data.push(aVal[ee]);
                }
            }

            return [dval,
                    dmin, 
                    dmax, 
                    ];
        },

        options()
        {
            var maxLimit =  1.05;
            var minLimit = -0.05;
            for(let ii=0; ii<this.datasets[0].data.length; ii++)
            {
                if(this.datasets[0].data[ii] != undefined)
                {
                    maxLimit = Math.max(maxLimit, this.datasets[0].data[ii]);
                    minLimit = Math.min(minLimit, this.datasets[0].data[ii]);
                }
            }

            //maxLimit = Math.min(maxLimit, 2.0);
            //minLimit = Math.max(minLimit, -1.0);

            console.log("Bla -1-")
            console.log(this.datasets)
            console.log(minLimit)
            console.log(maxLimit)

            return {
                responsive: true,
                maintainAspectRatio: false,
                plugins: 
                {
                    legend: 
                    {
                        display: false,
                        labels: 
                        {
                            usePointStyle: true,
                        },
                        position: 'right'
                    }
                },
                scales: 
                {
                    y:
                    {
                        min: minLimit,
                        max: maxLimit,
//                        min: -0.05,
//                        max: 1.05,
                        afterBuildTicks: axis => axis.ticks = [0.0, 1.0].map(v => ({ value: v })),
                        ticks: 
                        {
                            min: 0.0,
                            max: 1.0,
                            stepSize: 1,
//                            suggestedMin: 0.5,
//                            suggestedMax: 5.5,
                            callback: function(label, index, labels) 
                            {
                                console.log("Plot label")
                                console.log(label)
                                console.log(index)
                                console.log(labels)
                                switch (index) 
                                {
                                    case 0:
                                        return 'Min';
                                    case 1:
                                        return 'Max';
                                }
                            }
                        }
                    }
                }
            }
        },

    },

    methods: 
    {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
</style>
