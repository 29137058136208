<template>
    <div  ref="root"
         :style="divstyle">
    </div>
</template>

<script>

//import SpecService from '../service/SpecService'

export default {
    name: 'PlySegmentPie',
  
    inject: ['Plotly'],

    props: 
    {
        pdata: Array,
        width: String,
        modebar: {
            type: Boolean,
            default: false
        },
        labels: {
            type: Boolean,
            default: true
        }
    },

    created() 
    {
        this.utils = this.$store.state.utils;
        this.pconfig.displayModeBar = this.modebar;
        this.margin = 5;
        if(this.labels)
        {
            this.margin = 25;
        }        
    },

    data() {
        return {
            playout: [],
            pconfig: { displayModeBar: false }
        }
    },

    mounted() 
    {
        this.playout = this.getLayout();
        this.plotly();
    },

    computed: {
        divstyle: function (){
                return {
                    width: this.width,
                    height: '100%',
                    backgroundColor: 'grey'
                }
            },
    },

    methods: 
    {
        plotly()
        {
            this.Plotly.newPlot( this.$refs.root, 
                                 this.pdata, 
                                 this.playout,
                                 this.pconfig);
        },

        getPlotlySquareLength()
        {
            return this.$refs.root.clientWidth;
        },

        getLayout()
        {
            return {
                autoresize: true,
                height: this.getPlotlySquareLength(),
                width: this.getPlotlySquareLength(),
                title: "",
                showlegend: false,
                margin: 
                     {
                        l: this.margin,
                        r: this.margin,
                        b: this.margin,
                        t: this.margin,
                        pad: 5
                    },
                autosize: true,
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',

                polar: 
                {
                    barmode: "overlay",
                    bargap: 0,
                    radialaxis: 
                    {
//                        angle: 30,
                        showticklabels: false, 
                        ticks: '',
                        visible: true,
                        showgrid: true,
                        zeroline: false,
                        showline: false
                    },
                    angularaxis: 
                    {
                        //tick0: t0,
                        //dtick: dt,
                        direction: 'clockwise',
                        showticklabels: this.labels, 
                        ticks: '',
                        visible: true,
                        showgrid: false,
                        zeroline: false
                    },
                    hole: 0.3,
                },
                xaxis: 
                {
                    visible: false,
                    showgrid: false,
                    zeroline: false
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
</style>
