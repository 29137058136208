export default class PlotlyUtils
{
    static getSegmentPieDataAggregateFromPieDat(lbl, clr, piedat)
    {
        var analysis = {}

        for(var an of piedat.analysis)
        {
            for(var kk of lbl)
            {
                if(kk in analysis)
                {
                    analysis[kk] = analysis[kk] + parseFloat(an[kk])
                }
                else
                {
                    analysis[kk] = parseFloat(an[kk])
                }
            }
        }

        for(var kk in analysis)
        {
            analysis[kk] = analysis[kk] / piedat.analysis.length
        }

        var dt = 360.0 / lbl.length;
        var t0 = 0. * dt;

        var d0 = 
        {
            r: [],
            theta: lbl,
            //theta0: t0,
            offset: t0,
            name: "Horst",
            marker: 
            {
                color: clr,
                opacity: 0.7
            },
            type: "barpolar"
        }
        for(let itm of lbl)
        {
            if(analysis[itm])
            {
                d0.r.push(analysis[itm])
            }
            else
            {
                d0.r.push(0.0)
            }
        }

        return [d0]
    }

    static _createSegmentPieDataFromAnalysis(lbl, clr, name, analysis, offset, width)
    {
        var dt = 360.0 / lbl.length;
        var t0 = 0. * dt;
        var d0 = 
        {
            r: [],
            theta: lbl,
            //theta0: t0,
            offset: offset,
            width: width,
            name: name,
            marker: 
            {
                color: clr,
                opacity: 0.7
            },
            type: "barpolar"
        }

        for(let itm of lbl)
        {
            if(analysis[itm])
            {
                d0.r.push(parseFloat(analysis[itm]))
            }
            else
            {
                d0.r.push(0.0)
            }
        }
        return d0;
    }


    static getSegmentPieDataFromAnalysis(lbl, clr, analysis)
    {
        var ret = [];

        console.log(analysis)


        for(var an of analysis)
        {
            if(an.rowid == "Min")
            {
                ret.push(PlotlyUtils._createSegmentPieDataFromAnalysis(lbl, clr, "Min", an, 0.0, 0.75));
            }
    
            if(an.rowid == "Wert")
            {
                ret.push(PlotlyUtils._createSegmentPieDataFromAnalysis(lbl, clr, "Wert", an, 0.25, 0.5));
            }

            if(an.rowid == "Max")
            {
                ret.push(PlotlyUtils._createSegmentPieDataFromAnalysis(lbl, clr, "Max", an, 0.25, 0.75));
            }

        }

        return ret;
    }

    static getParCoordsFromAnalysis(lbl, analysis, normalize)
    {
        var amin = analysis[0];
        var aval = analysis[1];
        var amax = analysis[2];

        var tracemin = 
        {
            y: [],
            type: 'scatter',
            line:
            {
                color: 'green'
            }
        }

        var tracemax = 
        {
            y: [],
            type: 'scatter',
            line:
            {
                color: 'green'
            },
            fill: 'tonexty',
            fillcolor: 'green'
        }

        var traceval = 
        {
            y: [],
            type: 'scatter',
            line:
            {
                color: 'black'
            }
        }


        for(let itm of lbl)
        {
            if(amin[itm])
            {
                tracemin.y.push(100.0 * parseFloat(amin[itm]))
            }
            else
            {
                tracemin.y.push(0.0)
            }
            if(aval[itm])
            {
                traceval.y.push(100.0 * parseFloat(aval[itm]))
            }
            else
            {
                traceval.y.push(0.0)
            }
            if(amax[itm])
            {
                tracemax.y.push(100.0 * parseFloat(amax[itm]))
            }
            else
            {
                tracemax.y.push(0.0)
            }
        }

        if(normalize)
        {
            for(var ii=0; ii<traceval.y.length; ii++)
            {
                var d = tracemax.y[ii] - tracemin.y[ii]
                if(Math.abs(d) < 1e-6)
                {
                    if(Math.abs(traceval.y[ii]) > 1e-6)
                    {
                        traceval.y[ii] = 1.0;
                    }
                    tracemax.y[ii] = 0.0;
                    tracemin.y[ii] = 0.0;
                }
                else
                {
                    traceval.y[ii] = (traceval.y[ii] - tracemin.y[ii]) / d;
                    tracemax.y[ii] = 1.0;
                    tracemin.y[ii] = 0.0;
                }
            }
        }

        tracemin.x = lbl;
        tracemax.x = lbl;
        traceval.x = lbl;


        return [tracemin, tracemax, traceval];
    }

}