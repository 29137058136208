const AUTH_DATA="meltbuddy_auth_data";

export function isAuth()
{
    let token = window.sessionStorage.getItem(AUTH_DATA);
    if(token)
    {
        return true;
    }
    else
    {
        token = window.localStorage.getItem(AUTH_DATA);
        if(token)
        {
            return true;
        }
        
    }
    return false;
}

export function setAuth(data, sessionOnly)
{
    if(sessionOnly)
    {
        window.sessionStorage.setItem(AUTH_DATA, data);
    }
    else
    {
        window.localStorage.setItem(AUTH_DATA, data);
    }
}

export function clearAuth()
{
    window.sessionStorage.removeItem(AUTH_DATA);
    window.localStorage.removeItem(AUTH_DATA);
}
