<template>

    <div class="grid">
        <div  class="col">
            <h3>Steuerung</h3>
            <div class="field">
                <p> Die aktuelle Konfiguration zur Optimierung abschicken </p>

                <div v-if="isOptimizing">
                    <ProgressSpinner />
                </div>

                <div v-else>

                    <div v-if="canRemix">
                    <Button @click="onRemixShoot">
                        <div style="width: 10ch">
                            Remix!
                        </div>
                    </Button>
                    </div>

                    <div v-else>
                        <Button  id="mixbutton" 
                                :disabled="isMixDisabled"
                                @click="onShoot">
                            <div style="width: 10ch">
                                Mix!
                            </div>
                        </Button>
                    </div>

                </div>

            </div>

            <!--
            <div class="field">
                <p> Eine Kopie der aktuellen Konfiguration erstellen </p>
                <Button  id="remixbutton" 
                        @click="onVariant">
                    <div style="width: 10ch">
                        Remix!
                    </div>
                </Button>
            </div>
            -->
        </div> <!-- "col" -->

        <div  class="col">
            <h3>Status</h3>
            
            <p>Ausgewählte Ziele: {{numTargets}} </p>
            <p>Ausgewählte Materialien: {{numMaterials}} </p>
            <p> <b> {{status}} </b> </p>

        </div> <!-- "col" -->
    </div> <!-- "grid" -->


</template>

<script>

import Solution from '../../data/Solution'
import Utils from '../../service/Utils'

//import ServerConf from '../../assets/server.json'

export default {

    components: 
    {
    },

    emits: ['viewfocus'],

    data() 
    {
        return {
            isOptimizing: false
        }
    },

    computed: 
    {
        currentOrderBlockName()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.name + " [" + this.orderBlock.key + "]";
            }
            return undefined;
        },

        orderBlock()
        {
            return this.$store.state.currentOrderBlockVariant;
        },

        isMixDisabled()
        {
            if(this.orderBlock)
            {
                if(this.orderBlock.isReadOnly())
                {
                    return true;
                }
                else if(!this.orderBlock.isReadyForOpt())
                {
                    return true;
                }
                return false;
            }
            return true;
        },

        canRemix()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.hasConfigChanges();
            }
            return false;
        },

        numMaterials()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.getNumMaterialsSelected()
            }
            return 0;
        },

        numTargets()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.getNumTargetsSelected()
            }
            return 0;
        },

        status()
        {
            if(this.orderBlock)
            {
                if(this.orderBlock.hasFailed())
                {
                    return "Fehler: '" + this.orderBlock.getOptErrorMessage() + "'";
                }
                else if(this.orderBlock.hasResults())
                {
                    return "Geschafft!"
                }
                else if(this.orderBlock.isReadyForOpt())
                {
                    return "Bereit, wenn Sie es sind..."
                }
            }
            return "Konfiguration nicht abgeschlossen";
        }

    },

    created() 
    {
        this.utils = this.$store.state.utils;
    },

    methods: 
    {

        onVariant(evt)
        {
            Utils.pushDerivedOrderBlock(this.$store, true);
        },


        onRemixShoot(evt)
        {
            Utils.pushDerivedOrderBlock(this.$store, true);
            this.shoot(evt);
        },

        onShoot(evt)
        {
            this.shoot(evt);
        },



/*
        async onRemixShoot(evt)
        {
            this.isOptimizing = true
            Utils.pushDerivedOrderBlock(this.$store, true);
            await this.onShoot(evt)
        },

        async onShoot(evt)
        {
            this.isOptimizing = true
            var ob = Utils.getCurrentOrderBlock(this.$store);
            ob.clearResults();
            var msg = ob.toOpimizeJSON();

            console.log(msg)
            const server = process.env.VUE_APP_SIMPLEXSERVER + '/json/create';
            console.log(server)

            try 
            {
                console.log("SET OPTIMIZING TRUE")
                const response = await fetch(server, 
                {
                    method: "post",
                    headers: 
                    {
                        "Content-Type": "application/json"
                    },
                    body: msg
                });
                console.log(response)

                if (!response.ok) 
                {
                    const message = 'Error with Status Code: ' + response.status;
                    throw new Error(message);
                }
                const data = await response.json();

                this.isOptimizing = false
                console.log("SET OPTIMIZING FALSE")

                console.log("OPT DONE")
                console.log(data)

                if(data.status === "SUCCESS")
                {
                    for(var sos of data.solution)
                    {
                        var sova = new Solution(this.utils.nextUID(), 
                                                sos.targetKey, 
                                                sos.typeKey, 
                                                sos.target, 
                                                sos.type, 
                                                sos.value,
                                                true);
                        this.orderBlock.addSolution(sova);
                    }

                    this.$emit("viewfocus", {type: "results", key: this.orderBlock.key});

                }
                else if(data.status === "UNRESOLVED_ELEMENTS" || data.status === "INSUFFICIENT_SUPPLY")
                {
                    this.orderBlock.setOptError(data.status);
                    for(var sos of data.solution)
                    {
                        var sova = new Solution(this.utils.nextUID(), 
                                                sos.targetKey, 
                                                sos.typeKey, 
                                                sos.target, 
                                                sos.type, 
                                                sos.value,
                                                true);
                        this.orderBlock.addSolution(sova);
                    }

                    this.$emit("viewfocus", {type: "results", key: this.orderBlock.key});
                }
                else
                {
                    this.orderBlock.setOptError(data.status);
                }
            } 
            catch (error) 
            {
                console.log('Error: ' + error);
                throw error;
            }

        },
*/

        shoot(evt)
        {
            this.isOptimizing = true
            var ob = Utils.getCurrentOrderBlock(this.$store);
            ob.clearResults();
            var msg = ob.toOpimizeJSON();

            console.log(msg)
            const server = process.env.VUE_APP_SIMPLEXSERVER + '/json/create';
            console.log(server)

            try 
            {
                console.log("SET OPTIMIZING TRUE")
                fetch(server, 
                {
                    method: "post",
                    headers: 
                    {
                        "Content-Type": "application/json"
                    },
                    body: msg
                }).then((response) =>
                {
                    console.log(response)

                    if (!response.ok) 
                    {
                        const message = 'Error with Status Code: ' + response.status;
                        throw new Error(message);
                    }
                    response.json().then((data) =>
                    {
                        console.log("OPT DONE")
                        console.log(data)

                        if(data.status === "SUCCESS")
                        {
                            for(var sos of data.solution)
                            {
                                var sova = new Solution(this.utils.nextUID(), 
                                                        sos.targetKey, 
                                                        sos.typeKey, 
                                                        sos.target, 
                                                        sos.type, 
                                                        sos.value,
                                                        true);
                                this.orderBlock.addSolution(sova);
                            }

                            this.$emit("viewfocus", {type: "results", key: this.orderBlock.key});

                        }
                        else if(data.status === "UNRESOLVED_ELEMENTS" || data.status === "INSUFFICIENT_SUPPLY")
                        {
                            this.orderBlock.setOptError(data.status);
                            for(var sos of data.solution)
                            {
                                var sova = new Solution(this.utils.nextUID(), 
                                                        sos.targetKey, 
                                                        sos.typeKey, 
                                                        sos.target, 
                                                        sos.type, 
                                                        sos.value,
                                                        true);
                                this.orderBlock.addSolution(sova);
                            }

                            this.$emit("viewfocus", {type: "results", key: this.orderBlock.key});
                        }
                        else
                        {
                            this.orderBlock.setOptError(data.status);
                        }

                        this.isOptimizing = false
                        console.log("SET OPTIMIZING FALSE")
                    })
                })
            } 
            catch (error) 
            {
                console.log('Error: ' + error);
                throw error;
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>