export default class ElementValues
{
    static Elements = 
    {
        al: 
        {
            "id": "al",
            "label": "Al",
            "alias": ["alu"]
        },
        b: 
        {
            "id": "b",
            "label": "B",
            "alias": []
        },
        c: 
        {
            "id": "c",
            "label": "C",
            "alias": ["carb"]
        },
        ca: 
        {
            "id": "ca",
            "label": "Ca",
            "alias": []
        },
        co: 
        {
            "id": "co",
            "label": "Co",
            "alias": ["cob"]
        },
        cr: 
        {
            "id": "cr",
            "label": "Cr",
            "alias": ["chro"]
        },
        cu: 
        {
            "id": "cu",
            "label": "Cu",
            "alias": ["cop"]
        },
        fe: 
        {
            "id": "fe",
            "label": "Fe",
            "alias": []
        },
        mg: 
        {
            "id": "mg",
            "label": "Mg",
            "alias": []
        },
        mn: 
        {
            "id": "mn",
            "label": "Mn",
            "alias": ["mgn"]
        },
        mo: 
        {
            "id": "mo",
            "label": "Mo",
            "alias": ["moly"]
        },
        n: 
        {
            "id": "n",
            "label": "N",
            "alias": []
        },
        ni: 
        {
            "id": "ni",
            "label": "Ni",
            "alias": ["nick"]
        },
        nb: 
        {
            "id": "nb",
            "label": "Nb",
            "alias": [""]
        },
        p: 
        {
            "id": "p",
            "label": "P",
            "alias": ["phos"]
        },
        s: 
        {
            "id": "s",
            "label": "S",
            "alias": ["sulf"]
        },
        si: 
        {
            "id": "si",
            "label": "Si",
            "alias": ["sil"]
        },
        ti: 
        {
            "id": "ti",
            "label": "Ti",
            "alias": []
        },
        v: 
        {
            "id": "v",
            "label": "V",
            "alias": ["van"]
        },
        w: 
        {
            "id": "w",
            "label": "W",
            "alias": ["wol"]
        },
        zr: 
        {
            "id": "zr",
            "label": "Zr",
            "alias": []
        },
    }





    constructor()
    {
        this.values = {}
        for(var ee in ElementValues.Elements)
        {
            this.values[ee] = undefined
        }
    }

    setValue(ee, value)
    {
        if(ee in this.values)
        {
            if(value !== undefined)
            {
                this.values[ee] = parseFloat(value);
            }
        }
        else
        {
            console.trace();
            throw "Not an element: '" + ee + "'";
        }
    }

    getSum()
    {
        var s=0;
        for(var ee in ElementValues.Elements)
        {
            if(this.values[ee])
            {
                s = s + this.values[ee]
            }
        }
        return s;
    }

    getAsRow()
    {
        return this.values;
    }
}