import XLSX from 'xlsx';
import ElementValues from '../data/ElementValues';
import ExcelDataSource from '../data/ExcelDataSource';
import Material from '../data/Material';
import Target from '../data/Target'
import Utils from './Utils'

export default class ExcelTableUtils 
{
    static hasExcelData(store)
    {
        return store.state.excelsheets.size > 0;
    }

    static addExcelWorksheet(store, ws)
    {
        store.state.excelsheets.set(ws.getId(), ws);
        var arr = store.state.excelfiles.get(ws.filename);
        if(!arr)
        {
            arr = [];
            store.state.excelfiles.set(ws.filename, arr);
        }
        arr.push(ws);
    }

    static getExcelWorksheet(store, id)
    {
        return store.state.excelsheets.get(id);
    }

    static getExcelFileContens(store)
    {
        return store.state.excelfiles.values();
    }

/*
    static convertSheetJS2DataTableJSON(sheet) 
    {
        
        var range = XLSX.utils.decode_range(sheet['!ref']);

        var data = []
        var width = new Array(range.e.c+1)
        for(var ii=0; ii<width.length; ii++) {
            width[ii] = 20
        }

        for(var R = range.s.r; R <= range.e.r; ++R) {
            var tmp = {}
            for(var C = range.s.c; C <= range.e.c; ++C) {
                var cell_address = {c:C, r:R};
                var cell_ref = XLSX.utils.encode_cell(cell_address);
                var cname = XLSX.utils.encode_col(C);
                if(cell_ref in sheet) {
                    tmp[cname] = sheet[cell_ref].w
                }
                else {
                    tmp[cname] = ""
                }
                if(tmp[cname].length > width[C]) {
                    width[C] = tmp[cname].length
                }
            }
            tmp["_ROWID"] = XLSX.utils.encode_row(R);
            data.push(tmp)
        }

        var columns = []
        for(var C = range.s.c; C <= range.e.c; ++C) {
            columns.push({field: XLSX.utils.encode_col(C), 
                          header: XLSX.utils.encode_col(C),
                          style: "width:" + (width[C]+8) + "ch",
                          assignment: []
                        });
        }
    
        var ret = {data: data,
                   columns: columns,
                   rowKey: "_ROWID"}

        return (ret)
    }
*/
    static writeTargets2store(content, 
                              matchedColumns, 
                              filename,
                              sheetname,
                              store)
    {
        var dataSource = new ExcelDataSource(filename, sheetname);
        var orderBlock = Utils.getCurrentOrderBlock(store, true);

        content.getRows().forEach(element => 
        {
            var analysisMin = new ElementValues();
            var analysisMax = new ElementValues();
            var target = new Target(store.state.utils.nextUID(), dataSource, analysisMin, analysisMax)

            for(var k in matchedColumns)
            {
                var v = matchedColumns[k];
                if(v != null)
                {
                    if(v.isElement)
                    {
                        var sp = v.id.split(':');
                        var id = sp[0]
                        if(sp[1] == 'min')
                        {
                            var zz = parseFloat(element[k]);
                            if(isFinite(zz))
                            {
                                analysisMin.setValue(id, content.analysisValuesFactor * zz);
                            }    
                            else
                            {
                                analysisMin.setValue(id, undefined);
                            }

                        }
                        else if(sp[1] == 'max')
                        {
                            var zz = parseFloat(element[k]);
                            if(isFinite(zz))
                            {
                                analysisMax.setValue(id, content.analysisValuesFactor * zz);
                            }    
                            else
                            {
                                analysisMax.setValue(id, undefined);
                            }
                        }
                        else
                        {
                            console.log("Unknown id: '" + v.id + "' -> '" + sp[1] + "'");
                        }
                    }
                    else 
                    {
                        target.setProperty(v.id, element[k]);
                    }
                }
            }

            if(target.getName())
            {
                orderBlock.addTarget(target);
                store.state.targetsImportedCount++;
            }
        });
    }

    static writeTargets2store(content, 
                              matchedColumns, 
                              filename,
                              sheetname,
                              store)
    {
        var dataSource = new ExcelDataSource(filename, sheetname);
        var orderBlock = Utils.getCurrentOrderBlock(store, true);

        content.getRows().forEach(element => 
        {
            var analysisMin = new ElementValues();
            var analysisMax = new ElementValues();
            var target = new Target(store.state.utils.nextUID(), dataSource, analysisMin, analysisMax)

            for(var k in matchedColumns)
            {
                var v = matchedColumns[k];
                if(v != null)
                {
                    if(v.isElement)
                    {
                        var sp = v.id.split(':');
                        var id = sp[0]
                        if(sp[1] == 'min')
                        {
                            var zz = parseFloat(element[k]);
                            if(isFinite(zz))
                            {
                                analysisMin.setValue(id, content.analysisValuesFactor * zz);
                            }    
                            else
                            {
                                analysisMin.setValue(id, undefined);
                            }

                        }
                        else if(sp[1] == 'max')
                        {
                            var zz = parseFloat(element[k]);
                            if(isFinite(zz))
                            {
                                analysisMax.setValue(id, content.analysisValuesFactor * zz);
                            }    
                            else
                            {
                                analysisMax.setValue(id, undefined);
                            }
                        }
                        else
                        {
                            console.log("Unknown id: '" + v.id + "' -> '" + sp[1] + "'");
                        }
                    }
                    else 
                    {
                        target.setProperty(v.id, element[k]);
                    }
                }
            }

            if(target.getName())
            {
                if(analysisMax.getSum() > 0)
                {
                    orderBlock.addTarget(target);               
                }                
            }
        });
    }

    static writeMaterials2store(content, 
                                matchedColumns, 
                                filename,
                                sheetname,
                                store)
    {
        var dataSource = new ExcelDataSource(filename, sheetname);
        var orderBlock = Utils.getCurrentOrderBlock(store, true);

        content.getRows().forEach(element => 
        {
            var analysis = new ElementValues();
            var material = new Material(store.state.utils.nextUID(), dataSource, analysis)
            for(var k in matchedColumns)
            {
                var v = matchedColumns[k];
                if(v != null)
                {
                    if(v.isElement)
                    {
                        var zz = parseFloat(element[k]);
                        if(isFinite(zz))
                        {
                            analysis.setValue(v.id, content.analysisValuesFactor * zz);
                        }
                        else
                        {
                            analysis.setValue(v.id, 0.0);
                        }
                    }
                    else 
                    {
                        material.setProperty(v.id, element[k]);
                    }
                }
            }

            if(material.getName())
            {
                if(material.properties.mass_max)
                {
                    if(material.properties.price)
                    {
                        if(analysis.getSum() > 0)
                        {
                            orderBlock.addMaterial(material);
                        }
                    }
                }
            }

        });
    }


    static writeTargetsUpdate2store(content, 
                                    matchedColumns, 
                                    filename,
                                    sheetname,
                                    store)
    {
        var dataSource = new ExcelDataSource(filename, sheetname);
        var orderBlock = Utils.getCurrentOrderBlock(store, true);

        content.getRows().forEach(element => 
        {
            var analysisMin = new ElementValues();
            var analysisMax = new ElementValues();
            var target = new Target(store.state.utils.nextUID(), dataSource, analysisMin, analysisMax)

            for(var k in matchedColumns)
            {
                var v = matchedColumns[k];
                if(v != null)
                {
                    target.setProperty(v.id, element[k]);
                }
            }

            if(target.getName())
            {
                let succ = false;
                let ref = undefined;
                for(const cand of orderBlock.getTargets())
                {
                    if(cand.getName() == target.getName())
                    {
                        ref = cand
                        if(!succ)
                        {
                            if(cand.isUpdatable())
                            {
                                cand.overrideUpdatable(target);
                                succ = true;
                                break;
                            }
                        }
                    }
                }
                if(!succ)
                {
                    if(ref)
                    {
                        target.overrideNonUpdatable(ref);
                        orderBlock.addTarget(target);
                    }
                }
            }
        });
    }

}
