import ColorMap from './../assets/colors.json';
import SpecService from '../service/SpecService';
import OrderBlock from '../data/OrderBlock';
import Solution from '../data/Solution';

export default class Utils 
{
    constructor()
    {
        this.sortAnalysis = true;
        this.label2color = new Map()
        this.uidCnt = 0;
    }

    static getFeasibleWeight(val) 
    {
        let str = String(val);
        str = str.trim();
        if (!str) 
        {
            return NaN;
        }
        str = str.replace(',', '.');
        var value = parseFloat(str);

        console.log("Value: " + value)

        if(!isFinite(value))
        {
            return NaN;
        }
        if(value < 0)
        {
            return NaN;
        }

        return value;
    }

    static checkMinMax(minVal, maxVal)
    {
        if(isFinite(minVal) && isFinite(maxVal))
        {
            return minVal <= maxVal
        }
        return true
    }

    static isFeasiblePrice(val) 
    {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    }

    static deriveOrderBlockVariant(parent, vanilla)
    {
        var cpy = new OrderBlock();

        cpy.materialsMap = Utils.structuredMapCopyMe(parent.materialsMap);
        cpy.targetsMap = Utils.structuredMapCopyMe(parent.targetsMap);
        
        if(!vanilla)
        {
            for(var sol of parent.solution)
            {
                var solCpy = new Solution(sol.key, 
                                          sol.target, 
                                          sol.material, 
                                          sol.targetName, 
                                          sol.materialName, 
                                          sol.value);
                cpy.addSolution(solCpy);
            }
        }
        else
        {
            console.log("MAT Changes")
            console.log(parent.materialsMapChanges)
            if(parent.materialsMapChanges)
            {
                for(let mm of parent.materialsMapChanges.values())
                {
                    console.log("MAT Changes MM")
                    console.log(mm)
                    cpy.materialsMap.set(mm.key, mm)
                }            
            }    
        }

        return cpy;
    }

    static getRootOrderBlock(store)
    {
        return store.state.rootOrderBlockVariant;
    }

    static getCurrentOrderBlock(store, create=false)
    {
        if(!store.state.currentOrderBlockVariant && create)
        {
            store.commit('orderBlock', new OrderBlock("V0"));
        }
        return store.state.currentOrderBlockVariant;
    }

    static getOrderBlock(store, variantNum)
    {
        return store.state.orderBlockVariants.get(variantNum);
    }

    static pushDerivedOrderBlock(store, vanilla, parentVariantNum=undefined)
    {
        if(parentVariantNum === undefined)
        {
            parentVariantNum = store.state.currentOrderBlockVariant.key;
        }
        var parent = store.state.orderBlockVariants.get(parentVariantNum);
        //var key = store.state.orderBlockVariants.size;

        console.log("Parent")
        console.log(parent)

        var dob = Utils.deriveOrderBlockVariant(parent, vanilla);
        store.commit('orderBlock', dob);
        // IMPORTANT!!! Set key after "push" or there is no key...
        parent.derivedVariants.push(dob.key);
//        dob.name = parent.name + ":" + dob.key;
        dob.name = "REMIX " + parent.name + ":" + dob.key;
    }

    static setCurrentOrderBlock(store, variantNum)
    {
//        store.state.currentOrderBlockVariantNum = variantNum; 
//        store.state.currentOrderBlockVariant = store.state.orderBlockVariants.get(variantNum);
        var ob = store.state.orderBlockVariants.get(variantNum);
        store.commit('orderBlock', ob);
    }

    static structuredMapCopyMe(mp)
    {
        var ret = new Map();
        mp.forEach((value, key) => {
            ret.set(key, value.copyMe());
        })
        return ret;
    }

    nextUID()
    {
        this.uidCnt++;
        return this.uidCnt;
    }

    getRandomColor(lbl)
    {
        if(lbl in this.label2color)
        {
            return this.label2color[lbl]
        }
        else
        {
            let clr = '#'+(Math.random()*0xFFFFFF<<0).toString(16);
            this.label2color.set(lbl, clr)
            return clr
        }
    }

    hexToRGB(h) 
    {
        let r = 0, g = 0, b = 0;
        // 3 digits
        if (h.length == 4) 
        {
            r = "0x" + h[1] + h[1];
            g = "0x" + h[2] + h[2];
            b = "0x" + h[3] + h[3];
    
        } 
        else if (h.length == 7) 
        {
            // 6 digits
            r = "0x" + h[1] + h[2];
            g = "0x" + h[3] + h[4];
            b = "0x" + h[5] + h[6];
        }
        return "rgb("+ +r + "," + +g + "," + +b + ")";
    }

    getRandomColorRGB(lbl)
    {
        return this.hexToRGB(this.getRandomColor(lbl));
    }

    getColor(name) 
    {
        if(name in ColorMap.colors)
        {
            return ColorMap.colors[name];
        }
        return ColorMap.default;
    }

    getPieChartData(piedat)
    {
        let lbl = []
        for(var kk in SpecService.getElements())
        {
            var elm = SpecService.getElements()[kk]
            lbl.push(elm.id)
        }

        if(this.sortAnalysis)
        {
            lbl.sort(function(a, b){
                if(a.value < b.value)
                {
                    return -1;
                }
                else if(a.value > b.value)
                {
                    return 1;
                }
                else
                {
                    return 0;
                }
            })
        }
        let clr = []
        for(let itm of lbl)
        {
            clr.push(this.getColor(itm))
        }

        var analysis = {}

        for(var an of piedat.analysis)
        {
            for(var kk of lbl)
            {
                if(kk in analysis)
                {
                    analysis[kk] = analysis[kk] + parseFloat(an[kk])
                }
                else
                {
                    analysis[kk] = parseFloat(an[kk])
                }
            }
        }

        for(var kk in analysis)
        {
            analysis[kk] = analysis[kk] / piedat.analysis.length
        }

        let dt = []
        for(let itm of lbl)
        {
//            dt.push(analysis[itm])
            if(analysis[itm])
            {
                dt.push(analysis[itm])
            }
            else
            {
                dt.push(0.0)
            }
        }



        return {
            labels: lbl,
            datasets: [
                {
                    label: piedat.type,
                    borderColor: 'rgba(179,181,198,1)',
                    data: dt,
                    backgroundColor: clr,
                    //hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
                }
            ]
        }

    }

    getRadarBarChartData(piedat, targetdat, normalize=false)
    {
        var normMax = {};
        var normMin = {}


        let lbl = []
        for(var kk in SpecService.getElements())
        {
            var elm = SpecService.getElements()[kk]
            lbl.push(elm.id)

            normMax[elm.id] = 100.0;
            normMin[elm.id] = 0.0;
        }

        if(this.sortAnalysis)
        {
            lbl.sort(function(a, b){
                if(a.value < b.value)
                {
                    return -1;
                }
                else if(a.value > b.value)
                {
                    return 1;
                }
                else
                {
                    return 0;
                }
            })
        }

        let dt = []
        let clr = []
        let ds = []

        for(var analysis of piedat.analysis)
        {
            for(let itm of lbl)
            {
                dt.push(analysis[itm])
                clr.push(this.getColor(itm))
                if(isFinite(analysis[itm]))
                {
                    normMax[itm] = Math.max(normMax[itm], analysis[itm]);
                    normMin[itm] = Math.min(normMin[itm], analysis[itm]);
                }
            }
            var tlabel = piedat.type
            if("rowID" in analysis)
            {
                tlabel = tlabel + " " + analysis["rowID"]
            }

            ds.push({
                        label: tlabel,
                        borderColor: 'rgba(19,21,28,1)',
                        backgroundColor: 'rgba(19,21,28,1)',
                        data: dt,
                    })
        }

        const HEXPRE_MIN="#99"
        const HEXPRE_MAX="#66"

        for(let td of targetdat)
        {
            let ar_min = []
            let ar_max = []
            for(let itm of lbl)
            {
                if(itm in td.analysis[0])
                {
                    ar_min.push(td.analysis[0][itm]);
                    ar_max.push(td.analysis[1][itm]);
                    if(isFinite(td.analysis[0][itm]))
                    {
                        normMin[itm] = Math.min(normMin[itm], td.analysis[0][itm]);
                    }
                    if(isFinite(td.analysis[1][itm]))
                    {                    
                        normMax[itm] = Math.max(normMax[itm], td.analysis[1][itm]);
                    }
                }
                else
                {
                    ar_min.push(undefined)
                    ar_max.push(undefined)
                }
            }

            let clr = this.getRandomColor(td.type)

            ds.push({
                        label: td.type + " (MIN)",
                        borderColor: clr.replace("#", HEXPRE_MIN),
                        backgroundColor: clr.replace("#", HEXPRE_MIN),
                        data: ar_min,                        
                    })
            ds.push({
                        label: td.type + " (MAX)",
                        borderColor: clr.replace("#", HEXPRE_MAX),
                        backgroundColor: clr.replace("#", HEXPRE_MAX),
                        data: ar_max,
                    })
        }

        if(normalize)
        {
            for(var ddd of ds)
            {
                for(var ii=0; ii<ddd.data.length; ii++)
                {
                    console.log("BEFORE: " + ddd.data[ii]);
                    console.log("MIN=" + normMin[lbl[ii]] + "  MAX=" + normMax[lbl[ii]]);
                    ddd.data[ii] = (ddd.data[ii] - normMin[lbl[ii]]) / (normMax[lbl[ii]] - normMin[lbl[ii]]);
                    console.log("AFTER: " + ddd.data[ii]);
                }
            }
        }

        let ret = {
            labels: lbl,
            datasets: ds
        }

        return ret;
    }

    getFloatingBarChartData(piedat, targetdat)
    {
        let lbl = []
        for(var kk in SpecService.getElements())
        {
            var elm = SpecService.getElements()[kk]
            lbl.push(elm.id)
        }

        if(this.sortAnalysis)
        {
            lbl.sort(function(a, b){
                if(a.value < b.value)
                {
                    return -1;
                }
                else if(a.value > b.value)
                {
                    return 1;
                }
                else
                {
                    return 0;
                }
            })
        }

        let dt = []
        let clr = []
        let ds = []

        for(var analysis of piedat.analysis)
        {
            for(let itm of lbl)
            {
                if(analysis[itm] === undefined)
                {
                    dt.push([undefined, undefined])
                }
                else
                {
                    dt.push([0.0, analysis[itm]])
                }
                clr.push(this.getColor(itm))
            }
            var tlabel = piedat.type
            if("rowID" in analysis)
            {
                tlabel = tlabel + " " + analysis["rowID"]
            }

            ds.push({
                        label: tlabel,
                        borderColor: 'rgba(19,21,28,1)',
                        backgroundColor: 'rgba(19,21,28,1)',
                        data: dt,
                    })
        }

        const HEXPRE_MIN="#99"
        const HEXPRE_MAX="#66"

        for(let td of targetdat)
        {
//            let ar_min = []
//            let ar_max = []
            let ar = []
            for(let itm of lbl)
            {
                if(itm in td.analysis[0])
                {
                    ar.push([td.analysis[0][itm], td.analysis[1][itm]]);
                }
                else
                {
                    ar.push([undefined, undefined]);
                }
            }

            let clr = this.getRandomColor(td.type)

            /*
            ds.push({
                        label: td.type + " (MIN)",
                        borderColor: clr.replace("#", HEXPRE_MIN),
                        backgroundColor: clr.replace("#", HEXPRE_MIN),
                        data: ar_min,                        
                    })
            ds.push({
                        label: td.type + " (MAX)",
                        borderColor: clr.replace("#", HEXPRE_MAX),
                        backgroundColor: clr.replace("#", HEXPRE_MAX),
                        data: ar_max,
                    })
            */
            ds.push({
                        label: td.type,
                        borderColor: clr.replace("#", HEXPRE_MIN),
                        backgroundColor: clr.replace("#", HEXPRE_MIN),
                        data: ar,                        
                    })

        }

        let ret = {
            labels: lbl,
            datasets: ds
        }

        return ret;
    }

}
