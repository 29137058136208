<template>
    <div v-bind:style="divstyle">
        <Chart type="radar" :data="chartData" :options="lightOptions"/>
    </div>
</template>

<script>

export default 
{
    name: 'HelloRadar',
  
    props: 
    {
        piedat: Object,
        targetdat: {
            type: Object,
            default: []
        },
        hw: String
    },

    created() 
    {
        this.utils = this.$store.state.utils;
    },

    data() {
        return {
            lightOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                        position: 'right',
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    r: {
                        ticks: {
                            //color: 'red'
                            callback: function() {return ""},
                            backdropColor: "rgba(0, 0, 0, 0)"
                        }
                    }
                } 

            }
        }
    },

    computed: 
    {
        divstyle: function (){
            return {
                position: 'relative',
                height: this.hw,
                width: this.hw
            }
        },

        chartData: function () 
        {
            return this.utils.getRadarBarChartData(this.piedat, this.targetdat, true);
        },
    }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
</style>
