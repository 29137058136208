import XLSX from 'xlsx';
import SpecService from '../service/SpecService'

export default class ExcelTableData
{
    static ROW_KEY = "_ROWID";

    constructor(sheet, filename, sheetname, analysisValuesFactor=0.01)
    {
        this.analysisValuesFactor = analysisValuesFactor;
        this.filename = filename;
        this.sheetname = sheetname;

        var range = XLSX.utils.decode_range(sheet['!ref']);

        this.width = new Array(range.e.c+1)
        for(var ii=0; ii<this.width.length; ii++) 
        {
            this.width[ii] = 20
        }

        var R = range.s.r
        this.firstrow = this.convertRow(sheet, R, range.s.c, range.e.c)

        R++;
        this.rows = []
        for(; R <= range.e.r; ++R) 
        {
            this.rows.push(this.convertRow(sheet, R, range.s.c, range.e.c))
        }

        this.columns = []
        for(var C = range.s.c; C <= range.e.c; ++C) 
        {
            var ccc = XLSX.utils.encode_col(C)
            this.columns.push({field: ccc, 
                               header: ccc,
                               firstrow: this.firstrow[ccc],
                               style: "width:" + (this.width[C]+8) + "ch",
                               assignment: []
                              });
        }

        this.materialsOptionsDefaultMatchMissing = {};
        this.targetsOptionsDefaultMatchMissing = {};
        this.materialsOptionsDefaultMatch = {};
        this.targetsOptionsDefaultMatch = {};
        this.targetsOptionsMatchedElements = {};
        this.targetsUpdateOptionsMatchedElements = {}

        this.targetsUpdateOptionsDefaultMatchMissing = {};
        this.targetsUpdateOptionsDefaultMatch = {};


        this._setupMaterialsOptionsDefaultMatch();
        this._setupTargetsOptionsDefaultMatch();
        this._setupTargetsUpdateOptionsDefaultMatch();

        this.imported = false;
    }

    getId()
    {
        return this.filename + ":" + this.sheetname
    }

    getNonEmptyCount(colName)
    {
        var cnt = new Set();
        for(var ii=0; ii<this.rows.length; ii++)
        {
            var tmp = this.rows[ii][colName];
            if(tmp != undefined)
            {
                cnt.add(tmp)
            }
        }
        return cnt.size;
    }

    getFirstRow()
    {
        return this.firstrow
    }

    getRows()
    {
        return this.rows
    }

    convertRow(sheet, R, sc, ec)
    {
        var tmp = { };
        for(var C = sc; C <= ec; ++C) {
            var cell_address = {c:C, r:R};
            var cell_ref = XLSX.utils.encode_cell(cell_address);
            var cname = XLSX.utils.encode_col(C);
            if(cell_ref in sheet) {
//                tmp[cname] = sheet[cell_ref].w
                tmp[cname] = sheet[cell_ref].v

                if(tmp[cname].length > this.width[C]) 
                {
                    this.width[C] = tmp[cname].length
                }
    
            }
        }
        tmp[ExcelTableData.ROW_KEY] = XLSX.utils.encode_row(R);
        return tmp;
    }

    isMaterialsOptionsDefaultMatchComplete()
    {
        return Object.keys(this.materialsOptionsDefaultMatchMissing).length == 0
    }

    getMaterialsOptionsDefaultMatch()
    {
        return this.materialsOptionsDefaultMatch;
    }

    _setupMaterialsOptionsDefaultMatch()
    {
        for(var mp in SpecService.getMaterialProperties())
        {
            if(mp.required)
            {
                this.materialsOptionsDefaultMatchMissing["mp:" + mp] = true;
            }
        }
        for(var ee in SpecService.getElements())
        {
            this.materialsOptionsDefaultMatchMissing["ee:" + ee] = true;
        }

        this.materialsOptionsDefaultMatch = {};
        for(var kk in this.firstrow)
        {
            var succ = false;
            var v0 = this.firstrow[kk]
            if(isNaN(v0))
            {
                for(var mp in SpecService.getMaterialProperties())
                {
                    var v1 = SpecService.getMaterialProperties()[mp];
                    if(v0.toLowerCase().replace(/\s+/g, '') === v1.label.toLowerCase().replace(/\s+/g, '') || v0.toLowerCase() in v1.alias)
                    {
                        this.materialsOptionsDefaultMatch[kk] = {name: v1.label, id: v1.id, isMaterial: false, isElement: false};
                        succ = true;
                        delete this.materialsOptionsDefaultMatchMissing["mp:" + mp]
                        break;
                    }
                }

                if(!succ)
                {
                    for(var ee in SpecService.getElements())
                    {
                        var v1 = SpecService.getElements()[ee];
                        if(v0.toLowerCase().replace(/\s+/g, '') === v1.label.toLowerCase().replace(/\s+/g, '') || v0.toLowerCase() in v1.alias)
                        {
                            this.materialsOptionsDefaultMatch[kk] = {name: v1.label, id: v1.id, isMaterial: true, isElement: true};
                            delete this.materialsOptionsDefaultMatchMissing["ee:" + ee]
                            break;
                        }
                    }
                }
            }
        }
    }

    isTargetsOptionsDefaultMatchComplete()
    {
        if(Object.keys(this.targetsOptionsDefaultMatchMissing).length == 0)
        {
            if(Object.keys(this.targetsOptionsMatchedElements).length > 0)
            {
                return true;
            }
        }

        return false;
    }

    getTargetsOptionsDefaultMatch()
    {
        return this.targetsOptionsDefaultMatch;
    }

    _setupTargetsOptionsDefaultMatch()
    {
        for(var tp in SpecService.getTargetProperties())
        {
            if(SpecService.getTargetProperties()[tp].required)
            {
                this.targetsOptionsDefaultMatchMissing["tp:" + tp] = true;
            }
        }
/*
        for(var ee in SpecService.getElements())
        {
            this.targetsOptionsDefaultMatchMissing["ee:min:" + ee] = true;
            this.targetsOptionsDefaultMatchMissing["ee:max:" + ee] = true;
        }
*/
        this.targetsOptionsDefaultMatch = {};
        for(var kk in this.firstrow)
        {
            var succ = false;
            var v0 = this.firstrow[kk]

            if(isNaN(v0))
            {
                for(var tp in SpecService.getTargetProperties())
                {
                    var v1 = SpecService.getTargetProperties()[tp];
                    if(v0.toLowerCase().replace(/\s+/g, '') === v1.label.toLowerCase().replace(/\s+/g, '') || v0.toLowerCase() in v1.alias)
                    {
                        this.targetsOptionsDefaultMatch[kk] = {name: v1.label, id: v1.id, isMaterial: false, isElement: false};
                        succ = true;
                        delete this.targetsOptionsDefaultMatchMissing["tp:" + tp];
                        break;
                    }
                }

                if(!succ)
                {
                    var isMin = false;
                    var isMax = false;
                    v0 = v0.toLowerCase()
                    if(v0.search("min ") >= 0 || v0.search(" min") >= 0)
                    {
                        isMin = true;
                    }
                    if(v0.search("max ") >= 0 || v0.search(" max") >= 0)
                    {
                        isMax = true;
                    }
                    if(isMin && !isMax)
                    {
                        v0 = v0.replace("min", "").replace(/\s+/g, '');
                        for(var ee in SpecService.getElements())
                        {
                            var v1 = SpecService.getElements()[ee];
                            if(v0 === v1.label.toLowerCase().replace(/\s+/g, '') || v0 in v1.alias)
                            {
                                this.targetsOptionsDefaultMatch[kk] = {name: v1.label + " MIN", id: v1.id+":min", isMaterial: true, isElement: true};
//                                delete this.targetsOptionsDefaultMatchMissing["ee:min:" + ee];
                                this.targetsOptionsMatchedElements[ee] = true;
                                break;
                            }
                        }
                    }
                    else if(!isMin && isMax)
                    {
                        v0 = v0.replace("max", "").replace(/\s+/g, '')
                        for(var ee in SpecService.getElements())
                        {
                            var v1 = SpecService.getElements()[ee];
                            if(v0 === v1.label.toLowerCase().replace(/\s+/g, '') || v0 in v1.alias)
                            {
                                this.targetsOptionsDefaultMatch[kk] = {name: v1.label + " MAX", id: v1.id+":max", isMaterial: true, isElement: true};
//                                delete this.targetsOptionsDefaultMatchMissing["ee:max:" + ee];
                                this.targetsOptionsMatchedElements[ee] = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    isTargetsUpdateOptionsDefaultMatchComplete()
    {
        if(Object.keys(this.targetsUpdateOptionsDefaultMatchMissing).length == 0)
        {
            var cnt=0;
            for(var tp in SpecService.getTargetProperties())
            {
                if(SpecService.getTargetProperties()[tp].requiredOnUpdate)
                {
                    cnt++;
                }
            }

            if(Object.keys(this.targetsUpdateOptionsDefaultMatch).length >= cnt)
            {
                if(Object.keys(this.targetsUpdateOptionsMatchedElements) == 0)
                {
                    console.log("Update -5-")
                    return true;
                }
            }
        }

        return false;
    }

    getTargetsUpdateOptionsDefaultMatch()
    {
        return this.targetsUpdateOptionsDefaultMatch;
    }

    _setupTargetsUpdateOptionsDefaultMatch()
    {
        for(var tp in SpecService.getTargetProperties())
        {
            if(SpecService.getTargetProperties()[tp].requiredOnUpdate)
            {
                this.targetsUpdateOptionsDefaultMatchMissing["tp:" + tp] = true;
            }
        }

        this.targetsUpdateOptionsDefaultMatch = {};
        for(var kk in this.firstrow)
        {
            var succ = false;
            var v0 = this.firstrow[kk]
            if(isNaN(v0))
            {
                for(var tp in SpecService.getTargetProperties())
                {
                    var v1 = SpecService.getTargetProperties()[tp];
                    if(v0.toLowerCase().replace(/\s+/g, '') === v1.label.toLowerCase().replace(/\s+/g, '') || v0.toLowerCase() in v1.alias)
                    {
                        this.targetsUpdateOptionsDefaultMatch[kk] = {name: v1.label, id: v1.id, isMaterial: false, isElement: false};
                        succ = true;
                        delete this.targetsUpdateOptionsDefaultMatchMissing["tp:" + tp];
                        break;
                    }
                }

                if(!succ)
                {
                    var isMin = false;
                    var isMax = false;
                    v0 = v0.toLowerCase()
                    if(v0.search("min ") >= 0 || v0.search(" min") >= 0)
                    {
                        isMin = true;
                    }
                    if(v0.search("max ") >= 0 || v0.search(" max") >= 0)
                    {
                        isMax = true;
                    }
                    if(isMin && !isMax)
                    {
                        v0 = v0.replace("min", "").replace(/\s+/g, '');
                        for(var ee in SpecService.getElements())
                        {
                            var v1 = SpecService.getElements()[ee];
                            if(v0 === v1.label.toLowerCase().replace(/\s+/g, '') || v0 in v1.alias)
                            {
                                this.targetsUpdateOptionsMatchedElements[ee] = true;
                                break;
                            }
                        }
                    }
                    else if(!isMin && isMax)
                    {
                        v0 = v0.replace("max", "").replace(/\s+/g, '')
                        for(var ee in SpecService.getElements())
                        {
                            var v1 = SpecService.getElements()[ee];
                            if(v0 === v1.label.toLowerCase().replace(/\s+/g, '') || v0 in v1.alias)
                            {
                                this.targetsUpdateOptionsMatchedElements[ee] = true;
                                break;
                            }
                        }
                    }
                }
            }
        }        
    }


    static createMaterialsOptions()
    {
        var opt = []

        for(var kk in SpecService.getMaterialProperties())
        {
            var val = SpecService.getMaterialProperties()[kk];
            opt.push({name: val.label, id: val.id, isMaterial: false, isElement: false})
        }

        for(var kk in SpecService.getElements())
        {
            var val = SpecService.getElements()[kk];
            opt.push({name: val.label, id: val.id, isMaterial: true, isElement: true})
        }

        return opt;
    }

    static createTargetsOptions()
    {
        var opt = []

        for(var kk in SpecService.getTargetProperties())
        {
            var val = SpecService.getTargetProperties()[kk];
            opt.push({name: val.label, id: val.id, isMaterial: false, isElement: false});
        }

        for(var kk in SpecService.getElements())
        {
            var val = SpecService.getElements()[kk];
            opt.push({name: val.label + " MIN", id: val.id + ":min", isMaterial: true, isElement: true});
            opt.push({name: val.label + " MAX", id: val.id + ":max", isMaterial: true, isElement: true});
        }

        return opt;
    }

    static createTargetsUpdateOptions()
    {
        var opt = []

        for(var kk in SpecService.getTargetProperties())
        {
            var val = SpecService.getTargetProperties()[kk];
            if(val.updatable)
            {
                opt.push({name: val.label, id: val.id, isMaterial: false, isElement: false});
            }
        }

        return opt;
    }

}
