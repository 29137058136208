
export default class StyleService 
{
    static getSubTableCardStyle() 
    {
        return {
            padding: '20pt 20pt 20pt 20pt', 
            backgroundColor: 'var(--surface-100)'
        }

    }

    static formatCurrency(value) 
    {
        if(value != undefined)
        {
            value = parseFloat(value);
            return value.toLocaleString('de-DE', {minimumFractionDigits: 2, 
                                                    maximumFractionDigits: 2, 
                                                    style: 'currency', 
                                                    currency: 'EUR'});
        }
        return "";
    }

    static formatPercentage(value) 
    {
        if(value != undefined)
        {
            value = parseFloat(value);
            return value.toLocaleString('de-DE', {minimumFractionDigits: 3, 
                                                maximumFractionDigits: 3, 
                                                style: 'percent'});
        }
        return "";
    }

    static formatDecimal(value, unit)
    {
        if(value != undefined)
        {
            value = parseFloat(value);
            return value.toLocaleString('de-DE', {minimumFractionDigits: 2, 
                                                maximumFractionDigits: 2, 
                                                style: 'decimal'}) + " " + unit;
        }
        return "";
    }

    static styleElements()
    {
        return {
                    width: '100%', 
                    textAlign: 'right', 
                    //backgroundColor: 'green'
                }
    }

    static styleFeasibleElements()
    {
        return {
                    width: '100%', 
                    textAlign: 'right', 
                    //backgroundColor: 'green'
                }
    }

    static styleInfeasibleElements()
    {
        return {
                    width: '100%', 
                    textAlign: 'right', 
                    backgroundColor: 'red'
                }
    }

    static styleElementsHeader()
    {
        return {
                    width: '100%', 
                    textAlign: 'center', 
                    position: 'relative'
                    //backgroundColor: 'green'
                }
    }

    static styleTableHeaderElementColorFlag(element, hasValues, bgColor)
    {
        var ret = { 
            position: 'absolute', 
            top: '-1px', 
            right: '-1px', 
            width: '1ch', 
            height: '1ch', 
            backgroundColor: bgColor
        }

        if(!hasValues)
        {
            ret.display = 'none';
        }

        return ret;
    }

    static getLabelColumnStyle()
    {
        return {
                width: '8ch',
                padding: 0, 
                backgroundColor: 'var(--surface-ground)'
                }
    }


}
