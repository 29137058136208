export class WeightProperties
{
    constructor(key, targetName, minWeight, maxWeight, semiLinMinWeight, enabled)
    {
        if(maxWeight == undefined)
        {
            console.log(key)
            console.log(targetname)
            throw "Max Weight is undefined!"
        }

        this.key = key;
        this.targetName = targetName;
        this.minWeight = minWeight;
        this.maxWeight = maxWeight;
        this.semiLinMinWeight = semiLinMinWeight;
        this.enabled = enabled;
    }

    copyMe()
    {
        return new WeightProperties(this.key, 
                                    this.targetName,
                                    this.minWeight, 
                                    this.maxWeight,
                                    this.semiLinMinWeight,
                                    this.enabled)
    }

    hasDiffererences(other)
    {
        let ret = false;
        if(other)
        {
            if(this.minWeight != other.minWeight)
            {
                ret = true;
            }
    
            if(this.maxWeight != other.maxWeight)
            {
                ret = true;
            }
            if(this.semiLinMinWeight != other.semiLinMinWeight)
            {
                ret = true;
            }
            if(this.enabled != other.enabled)
            {
                ret = true;
            }
        }
        else
        {
            ret = true;
        }
        return ret;
    }
}

export class MaterialOptimizationProperties
{
    constructor(minWeightDefault, maxWeightDefault, semiLinMinWeightDefault)
    {
        if(semiLinMinWeightDefault == undefined)
        {
            semiLinMinWeightDefault = minWeightDefault;
        }
        this.defaults = new WeightProperties(-10, "defaults", minWeightDefault, maxWeightDefault, semiLinMinWeightDefault, false);
        this.perTargetWeightProperties = new Map();

        this.totalWeightProperties = undefined;
        this.perTargetCommonWeightProperties = undefined;
    }

    hasDiffererences(other)
    {
        let ret = false;
        if(this.totalWeightProperties)
        {
            ret = this.totalWeightProperties.hasDiffererences(other.totalWeightProperties);
        }
        else if(other.totalWeightProperties)
        {
            ret = true;
        }
        if(!ret)
        {
            if(this.perTargetCommonWeightProperties)
            {
                ret = this.perTargetCommonWeightProperties.hasDiffererences(other.perTargetCommonWeightProperties);
            }
            else if(other.perTargetCommonWeightProperties)
            {
                ret = true;
            }
        }
        if(!ret)
        {
            const kk = new Map();
            for(let k of this.perTargetWeightProperties.keys())
            {
                kk.set(k, k)
            }
            for(let k of other.perTargetWeightProperties.keys())
            {
                kk.set(k, k)
            }
            for(let k of kk.keys())
            {
                if(!ret)
                {
                    let ptw0 = this.perTargetWeightProperties.get(k);
                    let ptw1 = other.perTargetWeightProperties.get(k);
                    if(ptw0)
                    {
                        if(!ptw1)
                        {
                            ptw1 = other.getCreatePerTargetWeightProperties(ptw0.key, ptw0.targetName)
                        }
                    }
                    else 
                    {
                        if(ptw1)
                        {
                            ptw0 = this.getCreatePerTargetWeightProperties(ptw1.key, ptw1.targetName)
                        }
                    }
                    if(ptw0)
                    {
                        if(ptw1)
                        {
                            ret = ptw0.hasDiffererences(ptw1);
                        }
                    }                    
                }
            }
        }
        return ret;
    }

    getCreateTotalWeightProperties()
    {
        if(!this.totalWeightProperties)
        {
            this.totalWeightProperties = this.defaults.copyMe();
            this.totalWeightProperties.key = -1;
            this.totalWeightProperties.targetName = 'totalWeightProperties';    
        }
        return this.totalWeightProperties;
    }

    getCreatePerTargetCommonWeightProperties()
    {
        if(!this.perTargetCommonWeightProperties)
        {
            this.perTargetCommonWeightProperties = this.defaults.copyMe();
            this.perTargetCommonWeightProperties.key = -5;
            this.perTargetCommonWeightProperties.targetName = 'perTargetCommonWeightProperties';
            this.perTargetCommonWeightProperties.enabled = true;
        }
        return this.perTargetCommonWeightProperties;
    }

    getCreatePerTargetWeightProperties(key, targetName)
    {
        let ret = this.perTargetWeightProperties.get(key);
        if(!ret)
        {
            ret = this.defaults.copyMe();
            ret.key = key;
            ret.targetName = targetName;
            ret.enabled = true;
            this.perTargetWeightProperties.set(key, ret);
        }
        return ret;
    }


    copyMe()
    {
        let ret = new MaterialOptimizationProperties(this.defaults.minWeight, 
                                                     this.defaults.maxWeight, 
                                                     this.defaults.semiLinMinWeight);
        
        //ret.defaults  = this.defaults.copyMe();
        if(this.totalWeightProperties)
        {
            ret.totalWeightProperties = this.totalWeightProperties.copyMe();
        }
        if(this.perTargetCommonWeightProperties)
        {
            ret.perTargetCommonWeightProperties = this.perTargetCommonWeightProperties.copyMe();
        }
        for(let vv of this.perTargetWeightProperties.values())
        {
            let cp = vv.copyMe();
            ret.perTargetWeightProperties.set(cp.key, cp);
        }

        return ret;
    }
}

export default class Material
{
    static Properties =
    {
        type:
        {
            id: "type",
            label: "ART",
            alias: ["name", "ziel"],
            required: true,
            numeric: false
        },
        mass_min: 
        {
            id: "mass_min",
            label: "Menge Min",
            alias: ["min"],
            required: true,
            numeric: true
        },
        mass_max: 
        {
            id: "mass_max",
            label: "Menge Max",
            alias: ["max"],
            required: true,
            numeric: true
        },
        price: 
        {
            id: "price",
            label: "EURO/kg",
            alias: ["preis", "kosten", "cost"],
            required: true,
            numeric: true
        },
        minSemiLinear: 
        {
            id: "minSemiLinear",
            label: "semi",
            alias: ["semilb"],
            required: false,
            numeric: false
        }
    }

    constructor(key, dataSource, analysis)
    {
        this.key = key;
        this.dataSource = dataSource;
        this.analysis = analysis;
        this.useInOptimization = true;
        this.useMassMin = false;
        this.useMassMax = true;
        this.properties = {};
        for(var pp in Material.Properties)
        {
            this.properties[pp] = undefined;
        }

        //this.materialOptimizationProperties = new MaterialOptimizationProperties();
        this.materialOptimizationProperties = undefined;
    }

    copyMe()
    {
        var ret = new Material(this.key, this.dataSource, this.analysis);
        ret.properties = {... this.properties};

        if(this.materialOptimizationProperties)
        {
            ret.materialOptimizationProperties = this.materialOptimizationProperties.copyMe();
        }
        return ret;
    }

    hasDiffererences(other)
    {
        if(this.materialOptimizationProperties)
        {
            if(other.materialOptimizationProperties)
            {
                return this.materialOptimizationProperties.hasDiffererences(other.materialOptimizationProperties);
            }    
        }
        else if(!other.materialOptimizationProperties)
        {
            return false;
        }
        return true;
    }

    getCreateMaterialOptimizationProperties()
    {
        if(!this.materialOptimizationProperties)
        {
            this.materialOptimizationProperties = new MaterialOptimizationProperties(this.properties.mass_min, 
                                                                                     this.properties.mass_max, 
                                                                                     this.properties.minSemiLinear);
        }
        return this.materialOptimizationProperties;
    }

    getOptimizationTotalWeightProperties()
    {
        return this.getCreateMaterialOptimizationProperties().getCreateTotalWeightProperties();
    }

    getOptimizationPerTargetCommonWeightProperties()
    {
        return this.getCreateMaterialOptimizationProperties().getCreatePerTargetCommonWeightProperties();
    }

    getOptimizationPerTargetWeightProperties(targetKey, targetName)
    {
        return this.getCreateMaterialOptimizationProperties().getCreatePerTargetWeightProperties(targetKey, targetName);
    }

/*

    getOptimizationTotalMinWeight()
    {
        if(this.materialOptimizationProperties.totalWeightProperties.minWeight != undefined)
        {
            return this.materialOptimizationProperties.totalWeightProperties.minWeight
        }
        return this.properties.mass_min;
    }

    getOptimizationTotalMaxWeight()
    {
        if(this.materialOptimizationProperties.totalWeightProperties.maxWeight != undefined)
        {
            return this.materialOptimizationProperties.totalWeightProperties.maxWeight
        }
        return this.properties.mass_max;
    }

    getOptimizationTotalDisabled()
    {
        return this.materialOptimizationProperties.totalWeightProperties.disabled
    }

    setOptimizationTotalDisabled(val)
    {
        this.materialOptimizationProperties.totalWeightProperties.disabled = val;
    }

    setOptimizationTotalMinWeight(val)
    {
        this.materialOptimizationProperties.totalWeightProperties.minWeight = val;
    }

    setOptimizationTotalMaxWeight(val)
    {
        this.materialOptimizationProperties.totalWeightProperties.maxWeight = val;
    }

    getOptimizationTotalSemiLinMinWeight()
    {
        if(this.materialOptimizationProperties.totalWeightProperties.semiLinMinWeight != undefined)
        {
            return this.materialOptimizationProperties.totalWeightProperties.semiLinMinWeight
        }
        return this.properties.minSemiLinear;
    }

    setOptimizationTotalSemiLinMinWeight(val)
    {
        this.materialOptimizationProperties.totalWeightProperties.semiLinMinWeight = val;
    }

    getOptimizationTargetDisabled(target)
    {
        let ww = this.materialOptimizationProperties.perTargetWeightProperties.get(target);
        if(ww)
        {
            return ww.disabled;
        }
        return false;
    }

    setOptimizationTargetDisabled(target, val)
    {
        let ww = this.materialOptimizationProperties.perTargetWeightProperties.get(target);
        if(!ww)
        {
            ww = new WeightProperties(target);
            this.materialOptimizationProperties.perTargetWeightProperties.set(target, ww);
        }
        ww.disabled = val
    }

    getOptimizationTargetMinWeight(target)
    {
        let ww = this.materialOptimizationProperties.perTargetWeightProperties.get(target);
        if(ww)
        {
            if(ww.minWeight != undefined)
            {
                return ww.minWeight;
            }
        }
        return this.properties.mass_min;
    }

    setOptimizationTargetMinWeight(target, val)
    {
        let ww = this.materialOptimizationProperties.perTargetWeightProperties.get(target);
        if(!ww)
        {
            ww = new WeightProperties(target);
            this.materialOptimizationProperties.perTargetWeightProperties.set(target, ww);
        }
        ww.minWeight = val
    }

    getOptimizationTargetMaxWeight(target)
    {
        let ww = this.materialOptimizationProperties.perTargetWeightProperties.get(target);
        if(ww)
        {
            if(ww.maxWeight != undefined)
            {
                return ww.maxWeight;
            }
        }
        return this.properties.mass_max;
    }

    setOptimizationTargetMaxWeight(target, val)
    {
        let ww = this.materialOptimizationProperties.perTargetWeightProperties.get(target);
        if(!ww)
        {
            ww = new WeightProperties(target);
            this.materialOptimizationProperties.perTargetWeightProperties.set(target, ww);
        }
        ww.maxWeight = val
    }

    getOptimizationTargetSemiLinMinWeight(target)
    {
        let ww = this.materialOptimizationProperties.perTargetWeightProperties.get(target);
        if(ww)
        {
            if(ww.semiLinMinWeight != undefined)
            {
                return ww.semiLinMinWeight;
            }
        }
        return this.properties.minSemiLinear;
    }

    setOptimizationTargetSemiLinMinWeight(target, val)
    {
        let ww = this.materialOptimizationProperties.perTargetWeightProperties.get(target);
        if(!ww)
        {
            ww = new WeightProperties(target);
            this.materialOptimizationProperties.perTargetWeightProperties.set(target, ww);
        }
        ww.semiLinMinWeight = val
    }
*/

    setProperty(id, value)
    {
        var prop = Material.Properties[id];
        if(prop)
        {
            if(prop.numeric && value !== undefined)
            {
                this.properties[id] = parseFloat(value);
            }
            else
            {
                this.properties[id] = value;
            }
        }
        else
        {
            console.trace();
            throw "Unknown property: '" + id + "'";
        }
    }

    getName()
    {
        return this.properties.type;
    }

    getAsRow()
    {
        var ret = Object.assign({}, this.properties);
        ret.key = this.key;
        ret.useInOptimization = this.useInOptimization;
        ret.useMassMax = this.useMassMax;
        ret.useMassMin = this.useMassMin;
        ret.analysis = [this.analysis.getAsRow()];
        ret.analysis[0].key = "Values";

        return ret;
    }

}