import ElementValues from '../data/ElementValues';
import Material from '../data/Material';
import Target from '../data/Target';

export default class SpecService 
{
    static getElements() 
    {
        return ElementValues.Elements;
    }

    static getMaterialProperties() 
    {
        return Material.Properties;
    }

    static getTargetProperties() 
    {
        return Target.Properties;
    }

    static _sortedElementLables = [];

    static getSortedElementLabels()
    {
        if(SpecService._sortedElementLables.length == 0)
        {
            for(var kk in SpecService.getElements())
            {
                var elm = SpecService.getElements()[kk]
                SpecService._sortedElementLables.push(elm.id)
            }
    
            if(this.sortAnalysis)
            {
                SpecService._sortedElementLables.sort(function(a, b){
                    if(a.value < b.value)
                    {
                        return -1;
                    }
                    else if(a.value > b.value)
                    {
                        return 1;
                    }
                    else
                    {
                        return 0;
                    }
                })
            }
        }
        return SpecService._sortedElementLables;
    }

}
