<template>
<!--
    <div>
        <ToggleButton v-model="autoimport" onLabel="Autoimport" offLabel="Kein Autoimport"/>
    </div>
-->

    <Card>

        <template #title>
            Datei Import
        </template>

        <template #content>
            <div class="field-checkbox">
                <Checkbox  id="autoimportcheckbox" 
                           v-model="autoimport" 
                          :binary="true"/>
                <label for="autoimportcheckbox">Autoimport</label>
            </div>

            <FileUpload  name="fupload" 
                        :customUpload="true"
                        :multiple="true"
                         chooseLabel="Auswahl"
                         uploadLabel="Import"
                         cancelLabel="Abbruch"
                        :auto="true"
                        @uploader="myUploader">
                <template #empty>
                    <p>Drag and drop files to here to upload.</p>
                </template>
            </FileUpload>

            <div v-if="hasTreeData">
                <Divider/>
                <Tree :value="tree"
                    selectionMode="single"
                    @nodeSelect="onNodeSelect">

                    <template #default="slotProps">
                        <b>{{slotProps.node.label}}</b>
                    </template>

                    <template #menu="slotProps">

                        <div class="treechild">

                            <div v-if="slotProps.node.data.imported">
                                <i class="pi pi-check"></i>
                            </div>
                            <div v-else-if="slotProps.node.targetsComplete">
                                <Button  class="p-button-rounded p-button-outlined p-button-success small"
                                        @click="targetDirect(slotProps.node)"
                                         v-tooltip="{ value: 'Als Ziel importieren', disabled: false }">
                                    <img :src="targetArrowUp24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="targetEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Ziel Import editieren', disabled: false }">
                                    <img :src="targetDots24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-text small" 
                                            disabled="true">
                                    <img :src="warehouseArrowUp24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="materialsEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Material Import editieren', disabled: false }">
                                    <img :src="warehouseDots24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-text small" 
                                            disabled="true">
                                    <img :src="updateArrow24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="targetUpdateEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Update Import editieren', disabled: false }"
                                        :disabled="!canTargetUpdate(true)">
                                    <img :src="updateDots24"/>
                                </Button>
                            </div>
<!--                            <div v-else-if="slotProps.node.targetsUpdateComplete"> -->
                            <div v-else-if="canTargetUpdate(slotProps.node.targetsUpdateComplete)">
                                <Button  class="p-button-rounded p-button-text small"
                                            disabled="true">
                                    <img :src="targetArrowUp24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="targetEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Ziel Import editieren', disabled: false }">
                                    <img :src="targetDots24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-text small" 
                                            disabled="true">
                                    <img :src="warehouseArrowUp24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="materialsEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Material Import editieren', disabled: false }">
                                    <img :src="warehouseDots24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-success p-button-outlined small" 
                                        @click="targetUpdateDirect(slotProps.node)"
                                         v-tooltip="{ value: 'Als Update importieren', disabled: false }">
                                    <img :src="updateArrow24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="targetUpdateEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Update Import editieren', disabled: false }">
                                    <img :src="updateDots24"/>
                                </Button>
                            </div>
                            <div v-else-if="slotProps.node.materialsComplete">
                                <Button  class="p-button-rounded p-button-text small"
                                            disabled="true">
                                    <img :src="targetArrowUp24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="targetEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Ziel Import editieren', disabled: false }">
                                    <img :src="targetDots24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-success p-button-outlined small" 
                                        @click="materialsDirect(slotProps.node)"
                                         v-tooltip="{ value: 'Als Material importieren', disabled: false }">
                                    <img :src="warehouseArrowUp24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="materialsEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Material Import editieren', disabled: false }">
                                    <img :src="warehouseDots24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-text small" 
                                            disabled="true">
                                    <img :src="updateArrow24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="targetUpdateEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Update Import editieren', disabled: false }"
                                        :disabled="!canTargetUpdate(true)">
                                    <img :src="updateDots24"/>
                                </Button>
                            </div>
                            <div v-else>
                                <Button  class="p-button-rounded p-button-text small"
                                            disabled="true">
                                    <img :src="targetArrowUp24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-primary p-button-outlined small" 
                                        @click="targetEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Ziel Import editieren', disabled: false }">
                                    <img :src="targetDots24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-text small" 
                                            disabled="true">
                                    <img :src="warehouseArrowUp24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-primary p-button-outlined small" 
                                        @click="materialsEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Material Import editieren', disabled: false }">
                                    <img :src="warehouseDots24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-text small" 
                                         disabled="true">
                                    <img :src="updateArrow24"/>
                                </Button>
                                <Button  class="p-button-rounded p-button-secondary p-button-outlined small" 
                                        @click="targetUpdateEdit(slotProps.node)"
                                         v-tooltip="{ value: 'Update Import editieren', disabled: false }"
                                        :disabled="!canTargetUpdate(true)">
                                    <img :src="updateDots24"/>
                                </Button>
                            </div>

                            <div class="treechildlabel"> {{slotProps.node.label}} </div>

                        </div>

                    </template>

                </Tree>
            </div>

        </template>

        <template #footer>
            <em> {{numTargetsImported}} Ziele und {{numMaterialsImported}} Materialien importiert. </em>
        </template>

    </Card>

    <Dialog  footer="Footer" 
                v-model:visible="display"
            :style="{width: '80vw', backgroundColor: 'blue'}">

        <template #header>
            <h3>{{ currentContextData.filename }} - {{ currentContextData.title }}</h3>
        </template>

        <HelloExcelImport :content="currentContextData"
                            :filename="currentContextData.filename"
                            :sheetname="currentContextData.sheetname"
                            :mode="currentContextMode"
                            @xlsimport='onImportSuccess'/>

    </Dialog>
</template>

<script>

import XLSX from 'xlsx';
import HelloExcelImport from '../excel/MBExcelImport.vue'
import ExcelTableData from '../../service/ExcelTableData'
import ExcelTableUtils from '../../service/ExcelTableUtils'

//import Utils from '../../service/Utils'

import TargetArrowUp24 from "../../assets/target_arrowup_v02_24.png";
import TargetDots24 from "../../assets/target_dots_v02_24.png";
import WarehouseArrowUp24 from "../../assets/warehouse_arrowup_v02_24.png";
import WarehouseDots24 from "../../assets/warehouse_dots_v02_24.png";
import UpdateArrow24 from "../../assets/cart3_24.png"
import UpdateDots24 from "../../assets/cart0_24.png"

export default 
{
    components: 
    {
        HelloExcelImport
    },
  
    emits: ['dataimport'],

    data() 
    {
        return {
            files: null,
            display: false,
            currentContextData: null,
            currentContextMode: null,
            targetArrowUp24: TargetArrowUp24,
            targetDots24: TargetDots24,
            warehouseArrowUp24: WarehouseArrowUp24,
            warehouseDots24: WarehouseDots24,
            updateArrow24: UpdateArrow24,
            updateDots24: UpdateDots24,
            autoimport: true,
            selectedKey: []
        };
    },

    created() 
    {
        this.utils = this.$store.state.utils;
        this.filesFinished = new Map();
    },

    computed:
    {
        tree()
        {
            this.cnt0 = 0

            var files = { 
                type: "filesroot",
                key: this.cnt0,
                label: "Dateien",
                data: "files",
                icon: "pi pi-fw pi-folder",
                children: this.setupFilesTree()
            }
            this.cnt0 = this.cnt0 + 1;

            var ret = [files];
            this.cnt0 = this.cnt0 + 1;

            return ret;
        },

        orderBlock()
        {
            return this.$store.state.currentOrderBlockVariant;
        },

        numTargetsImported()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.getNumTargetsTotal();
            }
            return 0;
        },

        numMaterialsImported()
        {
            if(this.orderBlock)
            {
                return this.orderBlock.getNumMaterialsTotal();
            }
            return 0;
        },

        hasTreeData()
        {
            return ExcelTableUtils.hasExcelData(this.$store);
        }

    },

    methods: 
    {
        canTargetUpdate(flag)
        {
            if(flag)
            {
                if(this.$store.state.targetsImportedCount > 0)
                {
                    return true
                }
            }
            return false;
        },

        onNodeSelect(evt)
        {
            switch(evt.type)
            {
            default:
                break;
            }
        },

        startDrag (evt, nd) 
        {
            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('bommel', nd.dataKey)
  		},

        targetUpdateDirect(node)
        {
            var content = ExcelTableUtils.getExcelWorksheet(this.$store, node.dataKey);
            ExcelTableUtils.writeTargetsUpdate2store(content, 
                                                     content.getTargetsUpdateOptionsDefaultMatch(), 
                                                     content.filename,
                                                     content.sheetname,
                                                     this.$store);
            node.data.imported = true;

            this.emitDataImportFinished();
        },

        targetUpdateEdit(node)
        {
            var content = ExcelTableUtils.getExcelWorksheet(this.$store, node.dataKey);
            this.currentContextMode = "targetupdate"
            this.currentContextData = content
            this.display = true
        },

        targetDirect(node)
        {
            var content = ExcelTableUtils.getExcelWorksheet(this.$store, node.dataKey);
            ExcelTableUtils.writeTargets2store(content, 
                                               content.getTargetsOptionsDefaultMatch(), 
                                               content.filename,
                                               content.sheetname,
                                               this.$store);
            node.data.imported = true;

            this.emitDataImportFinished();
        },

        targetEdit(node)
        {
            var content = ExcelTableUtils.getExcelWorksheet(this.$store, node.dataKey);
            this.currentContextMode = "target"
            this.currentContextData = content
            this.display = true
        },

        materialsDirect(node)
        {
            var content = ExcelTableUtils.getExcelWorksheet(this.$store, node.dataKey);
            ExcelTableUtils.writeMaterials2store(content, 
                                                 content.getMaterialsOptionsDefaultMatch(), 
                                                 content.filename,
                                                 content.sheetname,
                                                 this.$store);
            node.data.imported = true;

            this.emitDataImportFinished();
        },

        materialsEdit(node)
        {
            var content = ExcelTableUtils.getExcelWorksheet(this.$store, node.dataKey);
            this.currentContextMode = "materials"
            this.currentContextData = content
            this.display = true
        },

        getIconClass(icn)
        {
            return "p-menuitem-icon " + icn;
        },

        onImportSuccess(msg)
        {
            this.display = false;

            console.log("Import Success!")
            console.log(this.numMaterialsImported)
            console.log(this.numTargetsImported)

            this.emitDataImportFinished();
        },

        emitDataImportFinished()
        {
            this.$emit("dataimport", 
                        {
                            status: 'finished',
                            materials: this.numMaterialsImported,
                            targets: this.numTargetsImported
                        })
        },

        myUploader(evt)
        {
            this.handleFiles(evt.files, this.$store);
        },

        async handleFiles(files, store)
        {
            this.files = files

            for(var f of this.files)
            {
                if(!this.filesFinished.has(f))
                {
                    this.filesFinished.set(f, f);
                    var tb = await this.readWorkbook(f);
                    for(var sh of tb)
                    {
                        ExcelTableUtils.addExcelWorksheet(store, sh)
                    }
                }
            }
        },

        readWorkbook(f)
        {
            var reader = new FileReader();

            return new Promise((resolve, reject) => {
                reader.onload = function(e) 
                {
                    var data = new Uint8Array(e.target.result);
                    var workbook = XLSX.read(data, {type: 'array'});
                    var tabs = []

                    workbook.SheetNames.forEach(element => {
                        if('!ref' in workbook.Sheets[element]) 
                        {
                            var tabDat = new ExcelTableData(workbook.Sheets[element], f.name, element)
                            tabs.push(tabDat);
                        }
                    });

                    resolve(tabs)
                }
                reader.onerror = function(e)
                {
                    reject("Bogus!")
                }
                reader.readAsArrayBuffer(f);
            });
        },

        setupFilesTree()
        {
            var root = [];

            var canAutoImport = {}
            canAutoImport.materialsDirectNode = [];
            canAutoImport.targetsDirectNode = [];
            canAutoImport.targetsUpdateDirectNode = [];

            for(const value of ExcelTableUtils.getExcelFileContens(this.$store)) 
            {                
                var fname = undefined;
                var chil = []
                for(var ii=0; ii<value.length; ii++)
                {
                    var updComp = false
                    var matComp = false;
                    var tarComp = value[ii].isTargetsOptionsDefaultMatchComplete()
                    if(!tarComp)
                    {
                        matComp = value[ii].isMaterialsOptionsDefaultMatchComplete()
                        if(!matComp)
                        {
                            updComp = value[ii].isTargetsUpdateOptionsDefaultMatchComplete()
                        }                        
                    }

                    fname = value[ii].filename;
                    var k = this.cnt0 + "_" + ii
                    var cnn = { 
                                key: k,
                                label: value[ii].sheetname,
                                data: value[ii],
                                type:  "menu",
                                dataKey: value[ii].getId(),
                                materialsComplete: matComp,
                                targetsComplete: tarComp,
                                targetsUpdateComplete: updComp
                    }
                    chil.push(cnn)

                    if(!cnn.data.imported && cnn.materialsComplete)
                    {
                        canAutoImport.materialsDirectNode.push(cnn)
                    }
                    if(!cnn.data.imported && cnn.targetsComplete)
                    {
                        canAutoImport.targetsDirectNode.push(cnn)
                    }
                    if(!cnn.data.imported && cnn.targetsUpdateComplete)
                    {
                        canAutoImport.targetsUpdateDirectNode.push(cnn)
                    }

                }

                root.push({ "key": this.cnt0,
                            "label": fname,
                            "data": fname,
                            "icon": "pi pi-fw pi-file-excel",
                            "children": chil})


                this.cnt0 = this.cnt0 + 1
            }

            if(this.autoimport)
            {
                if(canAutoImport.materialsDirectNode.length == 1 &&  canAutoImport.targetsDirectNode.length == 1)
                {
                    this.targetDirect(canAutoImport.targetsDirectNode[0])
                    this.materialsDirect(canAutoImport.materialsDirectNode[0])
                    
                    if(canAutoImport.targetsUpdateDirectNode.length == 1)
                    {
                        this.targetUpdateDirect(canAutoImport.targetsUpdateDirectNode[0])
                    }


                    this.$emit("dataimport", 
                               {
                                   status: 'finished',
                                   materials: this.numMaterialsImported,
                                   targets: this.numTargetsImported
                                })
                }
            }

            return root;
        },
    }


};
</script>

<style scoped>

button.small {
    padding-left: 3pt;
    padding-right: 3pt;
    padding-top: 3pt;
    padding-bottom: 3pt;
}

div.treechild {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

div.treechildlabel {
    height: 100%;
    padding-left: 8pt;
    padding-right: 8pt;
}

</style>

