<template>
    <div  ref="root0" 
         :style="divstyle">

        <Chart  type="bar" 
               :data="chartData" 
               :options="options"/>

    </div>
</template>

<script>

import SpecService from "../../../service/SpecService"
import StyleService from "../../../service/Styleservice"

export default 
{
    name: 'MBParCoords',
  
    props: 
    {
        materials: Object,
        targets: Object,
        width: String
    },

    computed:
    {
        rootDivWidth()
        {
            console.log(this.$refs)
            return this.$refs.root0.clientWidth;
        },

        rootDivHeight()
        {
            return this.$refs.root0.clientHeight;
        }

    },

    created() 
    {
        this.utils = this.$store.state.utils;        
    },

    data() 
    {
        return {
            normalize: true
        }
    },

    mounted() 
    {
    },

    computed: 
    {
        divstyle: function ()
        {                
            return {
                position: 'relative',
                width: this.width,
                //height: '550px',
                //backgroundColor: 'grey'
            }
        },

        chartData()
        {
            console.log(this.materials)
            console.log(this.targets)

            const HEXPRE="#99";

            var aMin = {};
            var aMax = {};

//            var fillcol = "rgba(64,236,99,0.5)"
//            var edgecol = "rgba(64,236,99,1.0)"

            var ret = {
                labels: SpecService.getSortedElementLabels(),
                datasets: []
            }

            for(var ii=0; ii<this.targets.length; ii++)
            {
                this.updateMinMax(aMin, aMax, this.targets[ii].analysis[0]);
                this.updateMinMax(aMin, aMax, this.targets[ii].analysis[1]);
            }
            this.updateMinMax(aMin, aMax, this.materials.analysis[0]);

            for(var ii=0; ii<this.targets.length; ii++)
            {
                let clr = this.utils.getRandomColor(this.targets[ii].type)

                var ds = {
                    label: this.targets[ii].type,
                    data:[],
                    backgroundColor: clr.replace("#", HEXPRE),
                    order: this.targets.length - ii
                }
                for(var ee of SpecService.getSortedElementLabels())
                {
                    var mi = Math.min(this.targets[ii].analysis[0][ee], this.targets[ii].analysis[1][ee]);
                    var ma = Math.max(this.targets[ii].analysis[0][ee], this.targets[ii].analysis[1][ee]);
                    mi = this.getNormalized(mi, aMin[ee], aMax[ee]);
                    ma = this.getNormalized(ma, aMin[ee], aMax[ee]);
                    ds.data.push([mi, ma]);
                }                
                ret.datasets.push(ds);
            }

            var ds0 = {
                label: this.materials.type,
                data:[],
                type: 'line',
                backgroundColor: 'black',
                order: 0
            }
            for(var ee of SpecService.getSortedElementLabels())
            {
                var val = this.getNormalized(this.materials.analysis[0][ee], aMin[ee], aMax[ee]);
                ds0.data.push(val);
            }
            ret.datasets.push(ds0);
            
            return ret
        },


        options()
        {
            var targets = this.targets;
            var materials = this.materials;

            return {
                responsive: true,
//                maintainAspectRatio: false,
                maintainAspectRatio: true,
                plugins: 
                {
                    legend: 
                    {
                        display: true,
                        labels: 
                        {
                            usePointStyle: true,
                        },
                        position: 'right'
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context)
                            {
                                console.log(context);

                                var ee = SpecService.getSortedElementLabels()[context.dataIndex];
                                if(context.datasetIndex >= targets.length)
                                {
                                    return StyleService.formatPercentage(materials.analysis[0][ee]);
                                }
                                else
                                {
                                    return "[" + StyleService.formatPercentage(targets[context.datasetIndex].analysis[0][ee]) + 
                                           " , " + StyleService.formatPercentage(targets[context.datasetIndex].analysis[1][ee]) +
                                           "]";
                                }
                            }
                        }
                    }
                },
                
                scales: 
                {
                    x: {
                        stacked: true
                    },
                    y:
                    {
                        stacked: false,
                        min: -0.05,
                        max: 1.05,
                        ticks: 
                        {
                            min: 0,
                            max: 1,
                            stepSize: 1,
                            callback: function(label, index, labels) 
                            {
                                console.log(label)
                                console.log(index)
                                console.log(labels)

                                switch (label) 
                                {
                                    case 0.0:
                                        return 'Min';
                                    case 1.0:
                                        return 'Max';
                                    default:
                                        return '';
                                }
                                /*
                                switch (index) 
                                {
                                    case 0:
                                        return 'Min';
                                    case 3:
                                        return 'Max';
                                }
                                */
                            }
                        }
                        
                    }
                }
                
            }
        },

    },

    methods: 
    {
        getNormalized(value, mi, ma)
        {
            var ret = value;
            if(value !== undefined && this.normalize)
            {
                if(mi !== undefined && ma !== undefined)
                {
                    if(Math.abs(ma - mi) > 1e-6)
                    {
                        ret = (value - mi) / (ma - mi);
                    }
                }
            }
            return ret;
        },

        updateMinMax(aMin, aMax, arr)
        {
            console.log(aMin)
            console.log(aMax)
            console.log(arr)

            for(var ee of SpecService.getSortedElementLabels())
            {
                if(arr[ee] !== undefined)
                {
                    if(ee in aMin)
                    {
                        aMin[ee] = Math.min(aMin[ee], arr[ee]);
                    }
                    else
                    {
                        aMin[ee] = arr[ee];
                    }
                    if(ee in aMax)
                    {
                        aMax[ee] = Math.max(aMax[ee], arr[ee]);
                    }
                    else
                    {
                        aMax[ee] = arr[ee];
                    }
                }
            }
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
</style>
