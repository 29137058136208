<template>

    <div class="conti">
        <Card>
            <template #title>
                Login
            </template>

            <template #content>

                <div class="p-fluid grid">
                    <div class="field col-12">
                        <span class="p-float-label">
                            <InputText id="login" type="text" v-model="login" />
                            <label for="login">Login</label>
                        </span>
                    </div>
                    <div class="field col-12 md">
                        <span class="p-float-label">
                            <Password id="password" v-model="password" :feedback="false" toggleMask/>
                            <label for="password">Password</label>
                        </span>
                    </div>
                </div>

            </template>


            <template #footer>
                <Button icon="pi pi-check" label="Login" @click="onClick" :disabled="isDisabled"/>
                <InlineMessage v-if="loginfailed"> Das hat leider nicht funktioniert </InlineMessage>
            </template>
        </Card>
    </div>

</template>

<script setup>

import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router'

import Card from 'primevue/card';
import Password from 'primevue/password';
import Button from 'primevue/button';
import InlineMessage from 'primevue/inlinemessage';

//import ServerConf from '../assets/server.json'
import { isAuth, setAuth, clearAuth } from '../service/Authentication'

const router = useRouter()

const password = ref()
const login = ref()
const sessionOnly = ref(true)
const loginfailed = ref(false)

const isDisabled = computed(() =>
{
    if(login.value === undefined)
    {
        return true;
    }
    if(password.value === undefined)
    {
        return true;
    }
    return false;
})


const askLogin = async () => 
{
    try 
    {
        /*
        const login = {
            login: 'lohmann',
            password: 'REMIX22'
        }
        */

        const request = 
        {
            login: login.value,
            password: password.value
        }

        const msg = JSON.stringify(request);
//        const server = ServerConf.simplexserver + '/auth/login';
        const server = process.env.VUE_APP_AUTHSERVER + '/auth/login';
        console.log(server)

        const response = await fetch(server, 
        {
            method: "post",
            headers: 
            {
                "Content-Type": "application/json"
            },
            body: msg
        });
        if (!response.ok) 
        {
            const message = 'Error with Status Code: ' + response.status;
            throw new Error(message);
        }
        console.log(response)
        const data = await response.json();
        console.log(data);
        console.log("Auth -1-? -> " + isAuth())

        if(data.auth)
        {
            setAuth(data, sessionOnly.value)
            loginfailed.value = false;
            router.push({ name: 'home' })
        }
        else
        {
            loginfailed.value = true;
        }
        console.log("Auth -2-? -> " + isAuth())

    } 
    catch (error) 
    {
        console.log('Error: ' + error);
        throw error;
    }
}

const onClick = (evt) => 
{
    askLogin();
}

onMounted(() => 
{
    clearAuth();

    console.log("-----ENV-----")
    console.log(process.env.NODE_ENV)
    console.log(process.env.BASE_URL)
    console.log(process.env.VUE_APP_SIMPLEXSERVER)
    console.log(process.env.VUE_APP_AUTHSERVER)

})

/*
onMounted(() => 
{
    askLogin();    
})
*/


</script>

<style>

.conti {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

</style>
