<template>
    <div  ref="root"
         :style="divstyle">
    </div>
</template>

<script>

export default 
{
    name: 'HelloPlyPie',
  
    inject: ['Plotly'],

    props: {
        arr: Array,
        values: Array,
        labels: String,
        width: String
    },

    utils: null,

    created() 
    {
        console.log("PLYPIE")
        console.log(this.arr)
        console.log(this.values)
        console.log(this.labels)

        this.pdata = []
        var cnt=0;
        for(var vl of this.values)
        {
            var pd = {
                type: 'pie',
                text: vl,
                textposition: 'inside',
                domain: 
                {
                    column: cnt
                },
                name: vl,
                hoverinfo: 'label+percent+name',
                hole: .4,
                values: [], 
                labels: [],
                sort: false
            }

            for(var obj of this.arr)
            {
                if(obj[vl])
                {
                    pd.values.push(obj[vl]);
                    pd.labels.push(obj[this.labels]);
                }
            }

            this.pdata.push(pd)
            cnt++;
        }
        this.playout = {
                    grid: 
                    {
                        rows: 1, 
                        columns: cnt
                    },
                    showlegend: false,
                    //height: "200", 
                    //width: "200",
                     margin: 
                     {
                        l: 5,
                        r: 5,
                        b: 5,
                        t: 5,
                        pad: 10
                    },
                    autosize: true,
                    paper_bgcolor: 'rgba(0,0,0,0)',
                    plot_bgcolor: 'rgba(0,0,0,0)'
                }
    },

    data() {
        return {
            pdata:  [],
            playout: []
        }
    },

    mounted() 
    {
        this.plotly();
    },

    computed: {
        divstyle: function (){
                return {
                    width: this.width,
                    //backgroundColor: 'blue'
                }
            },
    },

    methods: 
    {
        plotly()
        {
            this.Plotly.newPlot( this.$refs.root, this.pdata, this.playout);
        }
    }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
</style>
