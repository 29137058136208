<template>

    <DataTable :value="analysis" 
                responsiveLayout="scroll"
                class="p-datatable-sm"
               :autoLayout="true"
                showGridlines>
        <Column  field="key"                                                     
                :style="StyleService.getLabelColumnStyle()">
            <template #header>
                <Button  v-if="isToggle"
                        :label="toggleLabel" 
                        @click="onPercentageToAbsoluteClick($event)"
                        :style="{width: '100%', height: '100%'}"/>
            </template>
            <template #body="slotProps">
                <div class="font-bold my-rownumber">
                    {{slotProps.data.key}}
                </div>
            </template>
        </Column>
        <Column v-for="col of elementProperties" 
            :field="col.id" 
            :key="col.id">

            <template #header>
                <div :style="StyleService.styleElementsHeader()">
                    {{col.label}}
                    <!-- <Badge :style="{backgroundColor: 'red'}"/> -->

                    <div :style="getElementColorFlagStyle(col.id)"/>

                </div>
            </template>

            <template #body="sp">
                <div :style="getFeasibleOrInfesiableStyle(col.id, sp.data)">
                    {{formatValue(sp.data[col.id])}}
                </div>
            </template>
            
            <template v-if="hasFooter" #footer>
                <div :style="StyleService.styleElementsHeader()">
                    OK
                </div>
            </template>
        </Column>
    </DataTable>

    <div v-if="plotParCoords">
        <Divider/>

        <ParCoords :analysisRel="analysisRel" 
                    :analysisAbs="analysisAbs" 
                    width="100%" />
    </div>

</template>

<script>

import SpecService from "../../service/SpecService.js"
import StyleService from "../../service/Styleservice.js"

import PlotlyUtils from '../../service/PlotlyUtils'
import PlotlySegmentPie from '../charts/plotlyjs/MBPlotlySegmentPie.vue'
import PlotlyParCoords from '../charts/plotlyjs/MBPlotlyParCoords.vue'

import ParCoords from '../charts/chartjs/MBChartjsParCoords.vue'

export default {

    components: 
    {
        PlotlySegmentPie,
        PlotlyParCoords,
        ParCoords
    },

    props: 
    {
        analysisRel: Array,
//        analysisAbs: Array,
        analysisAbs: {
            type: Array,
            default: []
        },
        plotParCoords: {
            type: Boolean,
            default: true
        }
    },

    data() 
    {
        return {
            showElementsPercent: true
        }
    },

    computed: 
    {
        toggleLabel()
        {
            if(this.showElementsPercent)
            {
                return '%';
            }
            else
            {
                return 'abs';
            }
        },

        isToggle()
        {
            return this.hasRel && this.hasAbs;
        },

        hasRel()
        {
            return this.analysisRel.length > 0;
        },

        hasAbs()
        {
            return this.analysisAbs > 0;
        },

        hasFooter()
        {
            return this.analysis.length > 2;
        },

        analysis()
        {
            if(this.showElementsPercent)
            {
                return this.analysisRel;
            }
            else
            {
                return this.analysisAbs;
            }
        }
        
    },

    created() 
    {
        this.showElementsPercent = this.hasRel;
        this.elementProperties = [];
        for(let itm in SpecService.getElements())
        {
            this.elementProperties.push(SpecService.getElements()[itm])
        }
        this.StyleService = StyleService;
        this.utils = this.$store.state.utils;
        this.SpecService = SpecService;
    },

    methods: 
    {
        getFeasibleOrInfesiableStyle(element, obj)
        {
            console.log(element)
            console.log(obj)
            console.log(this.analysis)
            if(obj.key == "Wert")
            {
                if(obj[element] != undefined)
                {
                    const mi = this.analysis[0][element];
                    if(obj[element] < mi)
                    {
                        return StyleService.styleInfeasibleElements();
                    }
                    const ma = this.analysis[2][element];
                    if(obj[element] > ma)
                    {
                        return StyleService.styleInfeasibleElements();
                    }
                }
            }

            return StyleService.styleFeasibleElements();
        },

        hasElementValues(element)
        {
            for(var r of this.analysis)
            {
                if(r[element] !== undefined)
                {
                    return true;
                }
            }
            return false;
        },

        getElementColorFlagStyle(elem)
        {
            var clr = this.utils.getColor(elem);
            var hasVal = this.hasElementValues(elem);
            var ret = StyleService.styleTableHeaderElementColorFlag(elem, 
                                                                    hasVal,
                                                                    clr);
            return ret;
        },

        getSegmentPieData(analysis)
        {
            let lbl = SpecService.getSortedElementLabels();
            let clr = []
            for(let itm of lbl)
            {
                console.log("Color for: " + itm)
                console.log(this.utils.getColor(itm))
                clr.push(this.utils.getColor(itm))
            }
            var ret = PlotlyUtils.getSegmentPieDataFromAnalysis(lbl, clr, analysis)
            return ret;
        },

        getParCoordsData(analysis, normalize)
        {
            let lbl = SpecService.getSortedElementLabels();
            var ret = PlotlyUtils.getParCoordsFromAnalysis(lbl, analysis, normalize)
            return ret;
        },

        onPercentageToAbsoluteClick(event)
        {
            this.showElementsPercent = !this.showElementsPercent;
        },

        formatValue(value)
        {
            if(this.showElementsPercent)
            {
                return StyleService.formatPercentage(value);
            }
            else
            {
                return StyleService.formatDecimal(value, 'kg');
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

div.my-rownumber {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>