<template>
    <div v-bind:style="divstyle">
        <Chart type="polarArea" :data="chartData" :options="lightOptions"/>
    </div>
<!--    <Chart type="polarArea" :data="chartData" :options="lightOptions" v-bind:height="height" v-bind:width="width"/> -->
</template>

<script>

//import Utils from '../service/Utils'

export default {
    name: 'HelloPie',
  
    props: {
        piedat: Object,
        hw: String
    },

    utils: null,

    created() {
        //this.utils = new Utils();
        this.utils = this.$store.state.utils;
    },

    data() {
        return {
            lightOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    r: {
                        ticks: {
                            //color: 'red',
                            callback: function() {return ""},
                            backdropColor: "rgba(0, 0, 0, 0)"
                        }
                    }
                } 

            }
        }
    },

    computed: {

            divstyle: function (){
                return {
                    position: 'relative',
                    height: this.hw,
                    width: this.hw
                }
            },

            chartData: function () 
            {
                return this.utils.getPieChartData(this.piedat)
            },
/*
            chartData: function () {

                let analysis = this.piedat.analysis[0]

                let lbl = []
                let dt = []
                let clr = []
                let ut = this.utils

                for(let itm in analysis)
                {
                    lbl.push(itm)
                    dt.push(analysis[itm])
                    clr.push(ut.getColor(itm))
                }

                let ret = {
                    labels: lbl,
                    datasets: [
                        {
                            borderColor: 'rgba(179,181,198,1)',
                            data: dt,
                            backgroundColor: clr,
                            //hoverBackgroundColor: ["#64B5F6","#81C784","#FFB74D"]
                        }
                    ]
                }

                return ret;
            }
*/
    }
    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
</style>
