import SpecService from "../service/SpecService";

export default class Solution
{
    constructor(key, target, material, targetName, materialName, value, feasible)
    {
        this.key = key;
        this.targetName = targetName;
        this.materialName = materialName;
        this.target = target;
        this.material = material;
        this.value = value;
        this.cost = 0.0;
        this.absElements = undefined;
        this.feasible = feasible;
    }

    update(material)
    {
        this.cost = this.value * material.properties.price
        this.absElements = {}
        for(var kkk in SpecService.getElements())
        {
            if(material.analysis.values[kkk])
            {
                this.absElements[kkk] = ((material.analysis.values[kkk] / 100) * this.value)
            }
            else
            {
                this.absElements[kkk] = 0.0
            }
        }
    }


}