<template>

    <Toast/>

    <div v-if="hasRows">
        <DataTable :value="rows"  
                    v-model:expandedRows="expandedRows" 
                    dataKey="key"
                    @rowExpand="onRowExpand" 
                    @rowCollapse="onRowCollapse" 
                    editMode="cell" 
                    @cell-edit-complete="onCellEditComplete" 
                    class="editable-cells-table"
                    responsiveLayout="scroll"
                    removableSort
                    sortMode="multiple"
                   :multiSortMeta="multiSortMeta">

            <template #header>

                <i class="pi pi-shopping-cart"></i> Material zur Auswahl 

            </template>


            <Column :expander="true" 
                        headerStyle="width: 6rem">
                <template #header>

                    <Button  v-if="allCollapsed"
                                icon="pi pi-chevron-right" 
                            @click="expandAll" 
                                class="p-button-rounded p-button-text p-button-sm" />

                    <Button  v-else
                                icon="pi pi-chevron-down"  
                            @click="collapseAll"
                                class="p-button-rounded p-button-text p-button-sm"/>

                </template>
            </Column>

            <Column field="useInOptimization" 
                    key="key"
                    headerStyle="width: 6rem">
                
                <template #header>
                    <Checkbox  v-model="selectAllForOpt" 
                                :binary="true" 
                                @input="onSelectAllInput"
                                :disabled="isReadOnly"/>
                    
                    <span style="margin-left: 1.0rem"> 
                        Auswahl
                    </span>
                    
                </template>

                <template #body="slotProps">
                    <Checkbox  v-model="slotProps.data.useInOptimization" 
                                :binary="true" 
                                @click="onSelectClick(slotProps.data)"
                                :disabled="isReadOnly"/>
                </template>
            </Column>

            <Column headerStyle="width: 6rem">
                <template #body="slotProps">
                    <PiePlot v-bind:piedat="slotProps.data" hw="3em"/>
                </template>
            </Column>

            <Column :field="materialProperties.type.id" 
                    :key="materialProperties.type.id" 
                    :header="materialProperties.type.label"
                    :sortable="true">
            </Column>

            <Column field="useMassMin" 
                    key="key"
                    headerStyle="width: 6rem">
                
                <template #header>
                    <div style="text-align: right;width: 100%;">
                        <Checkbox  v-model="selectAllUseMassMin" 
                                    :binary="true" 
                                    @input="onSelectAllUseMassMin"
                                    :disabled="isReadOnly"/>
                    </div>
                </template>

                <template #body="slotProps">
                    <div style="text-align: right;">
                        <Checkbox  v-model="slotProps.data.useMassMin" 
                                    :binary="true" 
                                    @click="onUseMassMinClick(slotProps.data)"
                                    :disabled="isReadOnly"/>
                    </div>
                </template>
            </Column>

            <Column :field="materialProperties.mass_min.id" 
                    :key="materialProperties.mass_min.id" 
                    :header="materialProperties.mass_min.label"
                     dataType="numeric"
                    :sortable="true">
                <template #editor="slotProps">
                    <div v-if="slotProps.data.useMassMin">
                        <InputText  v-model="slotProps.data[slotProps.field]" 
                                    autofocus
                                    :disabled="isReadOnly"/>
                    </div>
                </template>
                <template #body="sp">
                    <div v-if="sp.data.useMassMin">
                        {{StyleService.formatDecimal(sp.data[sp.field], 'kg')}}
                    </div>
                    <div v-else>
                        <span disabled> 0 </span>
                    </div>
                </template>
            </Column>

            <Column field="useMassMax" 
                    key="key"
                    headerStyle="width: 6rem">
                
                <template #header>
                    <div style="text-align: right;width: 100%;">
                        <Checkbox  v-model="selectAllUseMassMax" 
                                    :binary="true" 
                                    @input="onSelectAllUseMassMax"
                                    :disabled="isReadOnly"/>
                    </div>
                </template>

                <template #body="slotProps">
                    <div style="text-align: right;">
                        <Checkbox  v-model="slotProps.data.useMassMax" 
                                    :binary="true" 
                                    @click="onUseMassMaxClick(slotProps.data)"
                                    :disabled="isReadOnly"/>
                    </div>
                </template>
            </Column>

            <Column :field="materialProperties.mass_max.id" 
                    :key="materialProperties.mass_max.id" 
                    :header="materialProperties.mass_max.label"
                     dataType="numeric"
                    :sortable="true">
                <template #editor="slotProps">
                    <div v-if="slotProps.data.useMassMax">
                        <InputText  v-model="slotProps.data[slotProps.field]" 
                                    autofocus
                                    :disabled="isReadOnly"/>
                    </div>
                </template>
                <template #body="sp">
                    <div v-if="sp.data.useMassMax">
                        {{StyleService.formatDecimal(sp.data[sp.field], 'kg')}}
                    </div>
                    <div v-else>
                        <span disabled> unbegrenzt </span>
                    </div>
                </template>
            </Column>


            <Column :field="materialProperties.price.id" 
                    :key="materialProperties.price.id" 
                    :header="materialProperties.price.label"
                     dataType="numeric"
                    :sortable="true">
                <template #editor="slotProps">
                    <InputText  v-model="slotProps.data[slotProps.field]" 
                                autofocus
                                :disabled="isReadOnly"/>
                </template>
                <template #body="sp">
                    {{StyleService.formatCurrency(sp.data[sp.field])}}
                </template>
            </Column>
<!--
            <Column selectionMode="multiple" headerStyle="width: 3em">
            </Column>
-->
            <template #expansion="sub">
                <div class="subtab0">
                    <h5>Components</h5>

                    <ElementsTable :analysisRel="sub.data.analysis"
                                    :plotParCoords="false"/>

                    
                    <Divider/>

                    <div class="subtab1">
                        <!--
                        <div class="subtab2">
                            <DataTable :value="sub.data.analysis" responsiveLayout="scroll">
                                <Column  v-for="col of elementProperties" 
                                        :field="col.id" 
                                        :header="col.label" 
                                        :key="col.id">
                                </Column>
                            </DataTable>
                        </div>
                        -->
                        <div class="subtab3">
                            <PiePlot v-bind:piedat="sub.data" hw="30%"/>
                            <MaterialTrace :materials="sub.data"
                                            :targets="targets"
                                            width="65%"/>

                            <!-- <RadarPlot v-if="hasTargets" v-bind:piedat="sub.data" v-bind:targetdat="targets" hw="30%"/> -->
                            <!--
                            <BarPlot v-if="hasTargets" 
                                        v-bind:piedat="sub.data" 
                                        v-bind:targetdat="targets" 
                                        h="100%" 
                                        w="65%"/>
                            

                            <SegmentPiePlot :pdata="getSegmentPieData(sub.data)" width="30%"/>
                            -->
                        </div>

                    </div>
                </div>
            </template>

            <template #footer>
                <i v-if="numSelected > 0" 
                   class="pi pi-check-circle"></i>
                <i v-else
                   class="pi pi-ban"></i>
                &nbsp; {{numSelected}} ausgewählt
            </template>

        </DataTable>
    </div>


</template>

<script>

import ExcelTableUtils from '../../service/ExcelTableUtils'
//import ExcelTableData from '../../service/ExcelTableData'
import SpecService from '../../service/SpecService'

import PiePlot from '../charts/chartjs/MBChartjsPieplot.vue'
import RadarPlot from '../charts/chartjs/MBChartjsRadarplot.vue'
import BarPlot from '../charts/chartjs/MBChartjsBarplot.vue'
import MaterialTrace from '../charts/chartjs/MBChartsjsMaterialTrace.vue'

import PlotlyUtils from '../../service/PlotlyUtils'
import SegmentPiePlot from '../charts/plotlyjs/MBPlotlySegmentPie.vue'

import WarehouseImg from "../../assets/warehouse_32.png"

import ElementsTable from '../subcomponents/MBElementsTable.vue'

import Utils from '../../service/Utils'
import StyleService from "../../service/Styleservice.js"


export default 
{

    components: 
    {
        PiePlot,
        RadarPlot,
        BarPlot,
        SegmentPiePlot,
        ElementsTable,
        MaterialTrace
    },

    data() 
    {
        return {
            expandedRows: [],
            warehouseImg: WarehouseImg,
            selectAllForOpt: true,
            multiSortMeta: [],
            selectAllUseMassMin: false,
            selectAllUseMassMax: true
        }
    },

    computed: 
    {
        rows()
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            if(ob)
            {
                return ob.getMaterialsAsRows();
            }
            else
            {
                return [];
            }
        },

        hasRows()
        {
            return this.rows.length > 0;
        },

        targets()
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            if(ob)
            {
                return ob.getTargetsAsRows();
            }
            else
            {
                return [];
            }
        },

        hasTargets()
        {
            return this.targets.length > 0;
        },

        allCollapsed()
        {
            return this.expandedRows.length == 0;
        },

        isReadOnly()
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            if(ob)
            {
                return ob.isReadOnly();
            }
            return true;
        },

        numSelected()
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            var ret = 0;
            for(var mt of ob.getMaterials())
            {
                if(mt.useInOptimization)
                {
                    ret++;
                }
            }
            return ret;
        }
    },

    created() 
    {
        this.materialProperties = SpecService.getMaterialProperties();
        this.elementProperties = [];
        for(let itm in SpecService.getElements())
        {
            this.elementProperties.push(SpecService.getElements()[itm])
        };
        this.utils = this.$store.state.utils;
        this.StyleService = StyleService;

        this.multiSortMeta =  [
            {field: this.materialProperties.price.id, order: -1}
        ]
    },

    methods: 
    {
        onSelectAllInput(value)
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            for(var mt of ob.getMaterials())
            {
                mt.useInOptimization = value;
            }
        },

        onSelectAllUseMassMin(value)
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            for(var mt of ob.getMaterials())
            {
                mt.useMassMin = value;
            }
        },

        onSelectAllUseMassMax(value)
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            for(var mt of ob.getMaterials())
            {
                mt.useMassMax = value;
            }
        },

        onSelectClick(evt)
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            var mt = ob.getMaterial(evt.key);
            mt.useInOptimization = !mt.useInOptimization;
        },

        onUseMassMinClick(evt)
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            var mt = ob.getMaterial(evt.key);
            mt.useMassMin = !mt.useMassMin;
        },

        onUseMassMaxClick(evt)
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            var mt = ob.getMaterial(evt.key);
            mt.useMassMax = !mt.useMassMax;
        },

        getSegmentPieData(piedat)
        {
            let lbl = SpecService.getSortedElementLabels();
            let clr = []
            for(let itm of lbl)
            {
                clr.push(this.utils.getColor(itm))
            }
            var ret = PlotlyUtils.getSegmentPieDataAggregateFromPieDat(lbl, clr, piedat)
            return ret;
        },

        onDrop (evt, list) 
        {
  			const item = evt.dataTransfer.getData('bommel')
            var content = ExcelTableUtils.getExcelWorksheet(this.$store, item);
            //var content = this.$store.state.exclsheets.get(item);

            ExcelTableUtils.writeMaterials2store(content, 
                                                 content.getMaterialsOptionsDefaultMatch(), 
                                                 content.filename,
                                                 content.sheetname,
                                                 this.$store)
            this.$toast.add({severity: 'info', summary: 'Materials Added', life: 2000});
  		},


        onCellEditComplete(event) 
        {
            let { data, newValue, field } = event;

            console.log("Edit complete")
            console.log(event)
            console.log(field)

            var ob = Utils.getCurrentOrderBlock(this.$store);
            console.log("STORE")
            console.log(ob)

            switch (field) 
            {
                case 'price':
                    if (this.isFeasiblePrice(newValue))
                        data[field] = newValue;
                    else
                        event.preventDefault();
                    break;
                case 'mass_min':
                    var nval = this.getFeasibleWeight(newValue)
                    if(!isNaN(nval))
                    {
                        ob.materialsMap.get(data.key).properties.mass_min = nval;
                        ob.materialsMap.get(data.key).properties.minSemiLinear = true
                    }
                    else
                    {
                        data.mass_min = ob.materialsMap.get(data.key).properties.mass_min;
                    }

                    break;
                default:
/*
                    if (newValue.trim().length > 0)
                        data[field] = newValue;
                    else
                        event.preventDefault();
*/
                    break;
            }        
        },

        getFeasibleWeight(val) 
        {
            let str = String(val);
            str = str.trim();
            if (!str) 
            {
                return NaN;
            }
            str = str.replace(',', '.');
            var value = parseFloat(str);

            console.log("Value: " + value)

            if(!isFinite(value))
            {
                return NaN;
            }
            if(value < 0)
            {
                return NaN;
            }

            return value;
        },


        isFeasiblePrice(val) 
        {
            let str = String(val);
            str = str.trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, "") || "0";
            var n = Math.floor(Number(str));
            return n !== Infinity && String(n) === str && n >= 0;
        },

        expandAll() 
        {
            this.expandedRows = this.rows.filter(p => p.key);
        },
        
        collapseAll() 
        {
            this.expandedRows = [];
        },
        
        onRowExpand(event) 
        {
        //    this.$toast.add({severity: 'info', summary: 'Row Expanded', detail: event.data.name, life: 3000});
        },
        
        onRowCollapse(event) 
        {
        //    this.$toast.add({severity: 'info', summary: 'Row Collapsed', detail: event.data.name, life: 3000});
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

div.subtab0 {
  padding: 2rem;
  width: 100%;
}

div.subtab1 {
  display: flex;
  flex-direction: column;
}

div.subtab2 {
  padding: 2rem;
}

div.subtab3 {
  display: flex;
  flex-direction: row;
}

div.droptab {
    width: 100%;
}

div.myheader {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

img.myheader {
    padding-right: 5pt;
    height: 100%;
    width: auto;
}
</style>