<template>

    <Toast/>

    <div v-if="hasRows">
        <DataTable :value="rows"  
                    v-model:expandedRows="expandedRows" 
                    dataKey="key"
                    @rowExpand="onRowExpand" 
                    @rowCollapse="onRowCollapse" 
                    editMode="cell" 
                    @cell-edit-complete="onCellEditComplete" 
                    class="editable-cells-table"
                    responsiveLayout="scroll">

            <template #header>
                <i class="pi pi-star"></i> Ziele
            </template>


            <Column :expander="true" 
                        headerStyle="width: 6rem">
                <template #header>

                    <Button  v-if="allCollapsed"
                                icon="pi pi-chevron-right" 
                            @click="expandAll" 
                                class="p-button-rounded p-button-text p-button-sm" />

                    <Button  v-else
                                icon="pi pi-chevron-down"  
                            @click="collapseAll"
                                class="p-button-rounded p-button-text p-button-sm"/>

                </template>
            </Column>

            <Column field="useInOptimization" 
                    key="key"
                    headerStyle="width: 6rem">
                
                <template #header>
                    <Checkbox  v-model="selectAllForOpt" 
                                :binary="true" 
                                @input="onSelectAllInput"
                                :disabled="isReadOnly"/>
                    
                    <span style="margin-left: 1.0rem"> 
                        Auswahl
                    </span>
                    
                </template>

                <template #body="slotProps">
                    <Checkbox  v-model="slotProps.data.useInOptimization" 
                                :binary="true" 
                                @click="onSelectClick(slotProps.data)"
                                :disabled="isReadOnly"/>
                </template>
            </Column>

            <Column headerStyle="width: 6rem">
                
                <template #body="slotProps">
                    <PiePlot v-bind:piedat="slotProps.data" hw="3em"/>
                </template>
                
            </Column>

            <Column :field="targetProperties.type.id" 
                    :key="targetProperties.type.id" 
                    :header="targetProperties.type.label">
            </Column>

            <Column :field="targetProperties.mass_min.id" 
                    :key="targetProperties.mass_min.id" 
                    :header="targetProperties.mass_min.label">
                <template #editor="slotProps">
                    <InputText  v-model="slotProps.data[slotProps.field]" 
                                autofocus
                               :disabled="isReadOnly"/>
                </template>
                <template #body="sp">
                    {{StyleService.formatDecimal(sp.data[sp.field], 'kg')}}
                </template>
            </Column>

            <Column :field="targetProperties.mass_max.id" 
                    :key="targetProperties.mass_max.id" 
                    :header="targetProperties.mass_max.label">
                <template #editor="slotProps">
                    <InputText v-model="slotProps.data[slotProps.field]" 
                                autofocus
                                :disabled="isReadOnly"/>
                </template>
                <template #body="sp">
                    {{StyleService.formatDecimal(sp.data[sp.field], 'kg')}}
                </template>
            </Column>

            <template #expansion="sub">
                <div class="subtab0">
                    <h5>Components</h5>

                    <ElementsTable :analysisRel="sub.data.analysis"
                                    :plotParCoords="false"/>

                    <div class="subtab1">
                        <div class="subtab3">
                            <PiePlot v-bind:piedat="sub.data" hw="30%"/>
                            <RadarPlot v-bind:piedat="sub.data"  hw="30%"/>
                            <BarPlot v-bind:piedat="sub.data" h="100%" w="39%"/>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <i v-if="numSelected > 0" 
                   class="pi pi-check-circle"></i>
                <i v-else
                   class="pi pi-ban"></i>
                &nbsp; {{numSelected}} ausgewählt
            </template>
        </DataTable>
    </div>



<!--
    <Panel :toggleable="true">

        <template #header>

            <div  class="droptab"
                 @drop="onDrop"
                 @dragover.prevent
                 @dragenter.prevent>

                <div class="myheader">
                    <img :src="targetImg" class="myheader"/>
                    <h3>Ziele</h3>
                </div>
            </div>

        </template>

        <div v-if="hasRows">
            <DataTable :value="rows"  
                        v-model:expandedRows="expandedRows" 
                        dataKey="key"
                        @rowExpand="onRowExpand" 
                        @rowCollapse="onRowCollapse" 
                        editMode="cell" 
                        @cell-edit-complete="onCellEditComplete" 
                        class="editable-cells-table"
                        responsiveLayout="scroll">

                <template #header>
                    header
                </template>

                <Column :expander="true" 
                         headerStyle="width: 6rem">
                    <template #header>

                        <Button  v-if="allCollapsed"
                                 icon="pi pi-chevron-right" 
                                @click="expandAll" 
                                 class="p-button-rounded p-button-text p-button-sm" />

                        <Button  v-else
                                 icon="pi pi-chevron-down"  
                                @click="collapseAll"
                                 class="p-button-rounded p-button-text p-button-sm"/>

                    </template>
                </Column>

                <Column field="useInOptimization" 
                        key="key"
                        headerStyle="width: 6rem">
                    
                    <template #header>
                        <Checkbox  v-model="selectAllForOpt" 
                                    :binary="true" 
                                    @input="onSelectAllInput"
                                    :disabled="isReadOnly"/>
                        
                        <span style="margin-left: 1.0rem"> 
                            Auswahl
                        </span>
                        
                    </template>

                    <template #body="slotProps">
                        <Checkbox  v-model="slotProps.data.useInOptimization" 
                                  :binary="true" 
                                  @click="onSelectClick(slotProps.data)"
                                  :disabled="isReadOnly"/>
                    </template>
                </Column>

                <Column headerStyle="width: 6rem">
                    
                    <template #body="slotProps">
                        <PiePlot v-bind:piedat="slotProps.data" hw="3em"/>
                    </template>
                    
                </Column>

                <Column :field="targetProperties.type.id" 
                        :key="targetProperties.type.id" 
                        :header="targetProperties.type.label">
                </Column>

                <Column :field="targetProperties.mass_min.id" :key="targetProperties.mass_min.id" :header="targetProperties.mass_min.label">
                    <template #editor="slotProps">
                        <InputText v-model="slotProps.data[slotProps.field]" 
                                   autofocus
                                  :disabled="isReadOnly"/>
                    </template>
                </Column>

                <Column :field="targetProperties.mass_max.id" :key="targetProperties.mass_max.id" :header="targetProperties.mass_max.label">
                    <template #editor="slotProps">
                        <InputText v-model="slotProps.data[slotProps.field]" 
                                   autofocus
                                  :disabled="isReadOnly"/>
                    </template>
                </Column>

                <template #expansion="sub">
                    <div class="subtab0">
                        <h5>Components</h5>

                        <ElementsTable :analysisRel="sub.data.analysis"
                                       :plotParCoords="false"/>

                        <div class="subtab1">
                            <div class="subtab3">
                                <PiePlot v-bind:piedat="sub.data" hw="30%"/>
                                <RadarPlot v-bind:piedat="sub.data"  hw="30%"/>
                                <BarPlot v-bind:piedat="sub.data" h="100%" w="39%"/>
                            </div>

                        </div>
                    </div>
                </template>

                <template #footer>
                Footer
                </template>
            </DataTable>
        </div>

    </Panel>
-->
</template>

<script>


import ExcelTableUtils from '../../service/ExcelTableUtils'
//import ExcelTableData from '../../service/ExcelTableData'
import SpecService from '../../service/SpecService'
import PiePlot from '../charts/chartjs/MBChartjsPieplot.vue'
import RadarPlot from '../charts/chartjs/MBChartjsRadarplot.vue'
import BarPlot from '../charts/chartjs/MBChartjsBarplot.vue'

import StyleService from "../../service/Styleservice.js"

import ElementsTable from '../subcomponents/MBElementsTable.vue'

import Utils from '../../service/Utils'

import TargetImg from "../../assets/target_32.png"

export default {

    components: 
    {
        PiePlot,
        RadarPlot,
        BarPlot,
        ElementsTable
    },

    data() 
    {
        return {
            expandedRows: [],
            targetImg: TargetImg,
            selectAllForOpt: true
        }
    },

    computed: 
    {
        rows()
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            if(ob)
            {
                return ob.getTargetsAsRows();
            }
            else
            {
                return [];
            }
        },

        hasRows()
        {
            return this.rows.length > 0;
        },

        allCollapsed()
        {
            return this.expandedRows.length == 0;
        },

        isReadOnly()
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            if(ob)
            {
                return ob.isReadOnly();
            }
            return true;
        },

        numSelected()
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            var ret = 0;
            for(var mt of ob.getTargets())
            {
//                if(mt.useInOptimization)
                if(mt.getUseInOptimization())
                {
                    ret++;
                }
            }
            return ret;
        }

    },

    created() 
    {
        this.targetProperties = SpecService.getTargetProperties();
        this.elementProperties = [];
        this.elementPropertiesMinMax = [];
        for(let itm in SpecService.getElements())
        {
            var el = SpecService.getElements()[itm]
            this.elementProperties.push(el)
            this.elementPropertiesMinMax.push({id: el.id + "_min",
                                               label: "MIN"})
            this.elementPropertiesMinMax.push({id: el.id + "_max",
                                               label: "MAX"})
        }
        this.StyleService = StyleService;
    },

    methods: 
    {
        onSelectAllInput(value)
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            for(var tgt of ob.getTargets())
            {
                tgt.setUseInOptimization(value);
            }
        },

        onSelectClick(evt)
        {
            var ob = Utils.getCurrentOrderBlock(this.$store);
            var tgt = ob.getTarget(evt.key);
            tgt.setUseInOptimization(!tgt.getUseInOptimization())
        },

        onDrop (evt, list) 
        {
  			const item = evt.dataTransfer.getData('bommel')
            var content = ExcelTableUtils.getExcelWorksheet(this.$store, item);
  
            ExcelTableUtils.writeTargets2store(content, 
                                               content.getTargetsOptionsDefaultMatch(), 
                                               content.filename,
                                               content.sheetname,
                                               this.$store)
            this.$toast.add({severity: 'info', summary: 'Targets Added', life: 2000});

  		},

        getLabelColumnStyle()
        {
            return {
                    width: '8ch',
                    padding: 0, 
                    backgroundColor: 'var(--surface-ground)'
                    }
        },

        onCellEditComplete(event) 
        {
            if(!this.isReadOnly)
            {
                let { data, newValue, field } = event;

                console.log("Edit complete")
                console.log(event)

                switch (field) 
                {
                case "useInOptimization":
                    break;
                case "mass_min":
                    var ob = Utils.getCurrentOrderBlock(this.$store);
                    var nval = Utils.getFeasibleWeight(newValue)
                    if(!isNaN(nval))
                    {
                        if(Utils.checkMinMax(nval, ob.targetsMap.get(data.key).properties.mass_max))
                        {
                            ob.targetsMap.get(data.key).properties.mass_min = nval;
                        }                        
                    }
                    break;
                case "mass_min":
                    var ob = Utils.getCurrentOrderBlock(this.$store);
                    var nval = Utils.getFeasibleWeight(newValue)
                    if(!isNaN(nval))
                    {
                        if(Utils.checkMinMax(ob.targetsMap.get(data.key).properties.mass_min, nval))
                        {
                            ob.targetsMap.get(data.key).properties.mass_max = nval;
                        }                        
                    }
                    break;
                default:
                    /*
                    var vv = parseFloat(newValue)
                    if(isFinite(vv))
                    {
                        data[field] = newValue;
                    }
                    else
                    {
                        event.preventDefault();
                    }
                    */
                    break;
                }

                console.log("OB")
                console.log(Utils.getCurrentOrderBlock(this.$store))
            }
        },

        expandAll() 
        {
            this.expandedRows = this.rows.filter(p => p.key);
        },
        
        collapseAll() 
        {
            this.expandedRows = [];
        },
        
        onRowExpand(event) 
        {
//            this.$toast.add({severity: 'info', summary: 'Row Expanded', detail: event.data.name, life: 3000});
        },
        
        onRowCollapse(event) 
        {
//            this.$toast.add({severity: 'info', summary: 'Row Collapsed', detail: event.data.name, life: 3000});
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->


<style scoped>

div.subtab0 {
  padding: 2rem;
  width: 100%;
}

div.subtab1 {
  display: flex;
  flex-direction: column;
}

div.subtab2 {
  padding: 2rem;
}

div.subtab3 {
  display: flex;
  flex-direction: row;
}

div.my-rownumber {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.droptab {
    width: 100%;
}

div.myheader {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

img.myheader {
    padding-right: 5pt;
    height: 100%;
    width: auto;
}
</style>