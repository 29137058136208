<template>
    <div v-bind:style="divstyle">
        <Chart type="bar" :data="chartData" :options="horizontalOptions" />
    </div>
</template>

<script>

export default 
{
    name: 'HelloBar',
  
    props: 
    {
        piedat: Object,
        targetdat: {
            type: Object,
            default: []
        },
        h: String,
        w: String
    },

    created() 
    {
        this.utils = this.$store.state.utils;
    },

    data() 
    {
        return {
/*
            lightOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    r: {
                        ticks: {
                            //color: 'red'
                            callback: function() {return ""},
                            backdropColor: "rgba(0, 0, 0, 0)"
                        }
                    }
                }
            },
*/
            horizontalOptions: {
                responsive: true,
                aspectRatio: 1.3,
                indexAxis: 'x',
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            }
        }
    },

    computed: 
    {

        divstyle: function ()
        {
            return {
                position: 'relative',
                height: this.h,
                width: this.w
            }
        },

        chartData: function () 
        {
            return this.utils.getFloatingBarChartData(this.piedat, this.targetdat);
            //return this.utils.getRadarBarChartData(this.piedat, this.targetdat)
        },
    }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped> 
</style>
